<template>
  <div v-if="showFormatATMScreen">
    <c-q-separator class="q-pt-lg q-pb-md" />
        
    <CampaignFormatATMScreenView />
  </div>
</template>

<script setup lang="ts">
import { useCampaignStore }         from '@/stores/campaignStore';
import CampaignFormatATMScreenView  from '@/components/campaign/campaignFormats/CampaignFormatATMScreenView.vue';
import { computed }                 from 'vue';
import { EnumCampaignFormats }      from '@/enums/enums';

const campaignStore = useCampaignStore();

const showFormatATMScreen = computed(() => {

  return (campaignStore.focusedCampaign.format == EnumCampaignFormats.ATM_SCREEN);
});
</script>

<style scoped>

</style>
