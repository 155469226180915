import { campaignInterface, campaignFilterInterface, campaignFormatATMScreenFilterInterface }	from '@/types/types.d';
import { CampaignModel }																																			from '@/models/CampaignModel';
import { CampaignFormatATMScreenFilterModel }																									from '@/models/CampaignFormatATMScreenFilterModel';

export class CampaignFilterModel implements campaignFilterInterface {
	filterCampaign								: campaignInterface;
	filterCampaignFormatATMScreen	: campaignFormatATMScreenFilterInterface;

	constructor () {
		this.filterCampaign									= new CampaignModel({});
		this.filterCampaignFormatATMScreen	= new CampaignFormatATMScreenFilterModel({});
	}
}
