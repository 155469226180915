import * as XLSX from 'xlsx';

export function cssStyleObjectToStyleString(cssStyleObject: object) {
  try {
    return Object.entries(cssStyleObject)
    .map(([key, value]) => `${key}: ${value}`)
    .join('; ')
    .trim();
      
  } catch (error) {
    return "";
  }
};

/**
 * This function converts a date string from the format "YYYY-MM-DD" to the format "DD/MM/YYYY".
 * 
 * @param dateStringToConvert - Date string in the format "YYYY-MM-DD".
 * @returns dateStringToConvert converted to the format "DD/MM/YYYY".
 */
export function formatDateStringYyyyMmDdAsDdMmYyyy(dateStringToConvert: string | undefined) {
  try {
    if (dateStringToConvert !== undefined) {
      return dateStringToConvert.split("-").reverse().join("/")
    
    } else {
      return "";
    }

  } catch (error) {
    return "";
  }
};

/**
 * This function converts a date string from the format "DD/MM/YYYY" to the format "YYYY-MM-DD".
 * 
 * @param dateStringToConvert - Date string in the format "DD/MM/YYYY".
 * @returns dateStringToConvert converted to the format "YYYY-MM-DD".
 */
export function formatDateStringDdMmYyyyAsYyyyMmDd(dateStringToConvert: string | undefined) {
  try {
    if (dateStringToConvert !== undefined) {
      return dateStringToConvert.split("/").reverse().join("-")
    
    } else {
      return "";
    }

  } catch (error) {
    return "";
  }
};

/**
 * This function converts a Date instance to a String in the "YYYY-MM-DD" format.
 * 
 * @param {Date} dateToConvert - The Date instance to be converted.
 * @param {string} [separator] - Optional; default "-".
 * 
 * @returns {string} dateToConvert as a string in the "YYYY-MM-DD" format.
 */
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export function convertDateToStringYyyyMmDd(dateToConvert: Date, separator: string = '-'): string {
  if (dateToConvert) {
    const year  = dateToConvert.getFullYear();
    const month = dateToConvert.getMonth() + 1; // Months are 0-indexed in JavaScript
    const day   = dateToConvert.getDate();

    return `${year}${separator}${String(month).padStart(2, '0')}${separator}${String(day).padStart(2, '0')}`;

  } else {
    return "";
  }
};

export function exportAsCsvFile(dataToExport: Array<object>, csvFilenameToCreate: string) {
  if (dataToExport) {
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    const sheet2CSVOpts = {
      /** Field Separator ("delimiter") */
      FS: ";",
  
      /** Record Separator ("row separator") */
      //RS: string;

      /** Remove trailing field separators in each record */
      strip: true,
  
      /** Include blank lines in the CSV output */
      blankrows: false,
  
      /** Skip hidden rows and columns in the CSV output */
      skipHidden: false,
  
      /** Force quotes around fields */
      forceQuotes: true,
  
      /** if true, return raw numbers; if false, return formatted numbers */
      rawNumbers: false
    }

    const csv   = XLSX.utils.sheet_to_csv(worksheet, sheet2CSVOpts);
    const blob  = new Blob(["\uFEFF"+ csv], { type: 'text/csv;charset=utf-8;' });
    const url   = URL.createObjectURL(blob);
    
    const link  = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', (csvFilenameToCreate ? csvFilenameToCreate : 'data.csv'));
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);    
  }
};
