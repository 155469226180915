<template>
	<c-q-btn
		v-if="authStore.currentUserCanAskAdjustmentCampaign 
		&& campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ON_APPROVAL.id"
		id="btn-request-adjustments"
		propBtnLabel="Solicitar Ajuste"
		:propBtnFunction="onRequestAdjustmentsBtnClick"
		:propBtnTransparent="true"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>
</template>

<script setup lang="ts">
import { EnumRequestedAdjustmentsModalType, EnumButtonSizes }	from '@/enums/enums';
import { usePagesUtilStore }																	from '@/stores/pagesUtilStore';
import { useAuthStore }																				from '@/stores/authStore'
import { useCampaignStore }																		from '@/stores/campaignStore';
import { CONST_CAMPAIGN_STATUS }															from '@/static/constants';

const pagesUtilStore	= usePagesUtilStore();
const authStore 			= useAuthStore();
const campaignStore		= useCampaignStore();

const onRequestAdjustmentsBtnClick = () => {
	pagesUtilStore.modalRequestedAdjustmentsModalType	= EnumRequestedAdjustmentsModalType.CREATE;
  pagesUtilStore.showModalRequestAdjustments				= true;
};
</script>

<style scoped>

</style>
