<template>
	<c-q-btn
		v-if="campaignStore.focusedCampaign.status != CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id 
		|| campaignStore.focusedCampaign.status != CONST_CAMPAIGN_STATUS.APPROVED.id"
		id="btn-cancel-create-new-campaign"
		propBtnLabel="Cancelar"
		:propBtnFunction="backToPreviousPage"
		:propBtnTransparent="true"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>
</template>

<script setup lang="ts">
import { EnumButtonSizes }	from '@/enums/enums';
import { useRouter }        from 'vue-router';
import { useCampaignStore }		from '@/stores/campaignStore';
import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';


const router = useRouter();
const campaignStore		= useCampaignStore();

const backToPreviousPage = () => {
	router.push({ name: "campaign"});
};
</script>

<style scoped>

</style>
