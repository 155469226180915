<template>
	<c-q-btn
	v-if="authStore.currentUserCanSendCampaignToApprove
    && !campaignStore.focusedCampaign.iscampanhaedit
    && (
				campaignStore.focusedCampaign.status == undefined
        //||campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id
        || campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.DRAFT.id
        || campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ON_EDITING.id
    )"
		id="btn-send-campaign-to-approve"
		propBtnLabel="Enviar para Aprovação"
		:propBtnFunction="onSendFocusedCampaignToApproveBtnClick"
		:propBtnTransparent="false"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>
</template>

<script setup lang="ts">
import { EnumButtonSizes, EnumRouteNames  }		from '@/enums/enums';
import { useCampaignStore }		from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages               from '@/utils/messages';
import { useRouter }												from 'vue-router';

import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';


const campaignStore		= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();
const authStore 			= useAuthStore();
const router = useRouter();

const onSendFocusedCampaignToApproveBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
    
    try{
      await campaignStore.saveFormatAtmImageSlotOnS3();
    }catch(error){
      throw new Error("Erro ao realizar upload da imagem.");
    }
  
   	await campaignStore.sendToApproveFocusedCampaign();
		
		messages.showSuccessMsg("Campanha enviada para aprovação com sucesso!");

    router.push({ name: EnumRouteNames.CAMPAIGN_VIEW });

  } catch (ErrorException: any) {
    messages.showErrorMsg(ErrorException.message);
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};
</script>

<style scoped>

</style>
