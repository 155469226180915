<template>
	<c-q-btn
	v-if="authStore.currentUserCanEditCampaign 
		&& (campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.APPROVED.id
		|| campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id
		|| campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.DRAFT.id
		|| campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ON_EDITING.id
		|| 
		(campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.PUBLISHED.id
			&& diferancaEntreDatas(campaignStore.focusedCampaign.startDate)
		))"
		id="btn-edit-campaign"
		propBtnLabel="Editar"
		:propBtnFunction="campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.APPROVED.id ? abreDialogo : onEditCampaignBtnClick"
		:propBtnTransparent="true"
		:propBtnSize="props.propBtnSize"
		:disable="disableEditFocusedCampaignsBtn"
	/>

	<ModalConfirmationDialog
			propDialogTitle="Atenção!"
			propConfirmationMsg="Essa campanha foi aprovada! Caso seja editada, ela precisará ser novamente enviada para aprovação! Deseja continuar?"
			propDialogType="editar"
			@confirm="onEditCampaignBtnClick"
		/>
</template>

<script setup lang="ts">
import ModalConfirmationDialog		from '@/views/modals/ModalConfirmationDialog.vue';
import { EnumButtonSizes, EnumRouteNames }	from '@/enums/enums';
import { useCampaignStore }                 from '@/stores/campaignStore';
import { usePagesUtilStore }                from '@/stores/pagesUtilStore';
import { useRouter }												from 'vue-router';
import messages                             from '@/utils/messages';
import { CONST_CAMPAIGN_STATUS }						from '@/static/constants';
import { useAuthStore }															from '@/stores/authStore';
import {computed }													from 'vue'
import moment from 'moment';

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();
const authStore				= useAuthStore();

const router = useRouter();

const props = defineProps({
  propBtnSize: {
		type			: String as () => keyof typeof EnumButtonSizes,
		required	: false,
		default		: EnumButtonSizes.LARGE_1
	}
});

const diferancaEntreDatas  = ( data : string | undefined ) => {

	const hoje = moment();

	data = campaignStore.focusedCampaign.startDate;

	const data1 = moment(data);

	const diferenca = data1.diff(hoje, 'days');

	return diferenca >= 3;
	
}

const disableEditFocusedCampaignsBtn = computed(() => {
	try {
		return campaignStore.campaignCanBeEdited(campaignStore.focusedCampaign);
		
	} catch (error) {
		return true;
	}
})

//NOVO
const abreDialogo = () => {
  pagesUtilStore.showModalConfirmationEditCampaign = true;
}

const onEditCampaignBtnClick = async () => {
	try {
    pagesUtilStore.showLoading = true;
  
		if (campaignStore.focusedCampaign?.status != CONST_CAMPAIGN_STATUS.ON_EDITING.id) {
    	await campaignStore.editFocusedCampaign();
		}
		
		router.push({ name: EnumRouteNames.CAMPAIGN_EDIT });

  } catch (errorEditingCampaign) {
    messages.showErrorMsg("Erro ao tentar abrir a campanha para edição...");
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};

//NOVO

</script>

<style scoped>

</style>
