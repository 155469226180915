<template>
	<div>
		<div class="row fit no-wrap items-top justify-between q-pb-md" style=" overflow: none; ">
			<span class="midia-app-title-lg">{{ props.propModalTitle }}</span>
			
			<ButtonCloseModal style=" left: 12px; top: -4px; " />
		</div>

		<div v-if="props.propModalDescription" class="row fit no-wrap q-pb-md" style=" overflow: auto; ">
			<span class="midia-app-caption-modal-lg">{{ props.propModalDescription }}</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import ButtonCloseModal	from '@/components/ButtonCloseModal.vue';

const props = defineProps({
  propModalTitle: {
    type      : String,
		required	: true
	},

  propModalDescription: {
    type      : String,
		required	: false
	}
});
</script>

<style scoped>

</style>
