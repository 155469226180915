<template>
  <div>
    <q-dialog
      v-model="pagesUtilStore.showModalPageCampaignsTableActions"
      :no-backdrop-dismiss="true"
    >
      <q-card style=" width: 127px; padding: 8px; ">
        <div style=" display: flex; flex-direction: column; gap: 10px; ">
          <BtnViewFocusedCampaign
            v-if="props.propShowBtnViewFocusedCampaign"
            style=" width: 100%; "
            :propBtnSize="EnumButtonSizes.SMALL"
          />

         <!-- <BtnEditFocusedCampaign
            v-if="props.propShowBtnEditFocusedCampaign"
            style=" width: 100%; "
            :propBtnSize="EnumButtonSizes.SMALL"
          /> -->
          
          <BtnDeleteFocusedCampaign
            v-if="props.propShowBtnDeleteFocusedCampaign"
            style=" width: 100%; "
            :propBtnSize="EnumButtonSizes.SMALL"
          />
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup lang="ts">
import BtnViewFocusedCampaign		from '@/components/campaign/actionButtons/BtnViewFocusedCampaign.vue';
import BtnEditFocusedCampaign		from '@/components/campaign/actionButtons/BtnEditFocusedCampaign.vue';
import BtnDeleteFocusedCampaign	from '@/components/campaign/actionButtons/BtnDeleteFocusedCampaign.vue';
import { EnumButtonSizes }      from '@/enums/enums';
import { usePagesUtilStore }    from '@/stores/pagesUtilStore';

const pagesUtilStore  = usePagesUtilStore();

const props = defineProps({
  propShowBtnViewFocusedCampaign: {
    type      : Boolean,
    required  : true
  },

  propShowBtnEditFocusedCampaign: {
    type      : Boolean,
    required  : true
  },

  propShowBtnDeleteFocusedCampaign: {
    type      : Boolean,
    required  : true
  }
});
</script>

<style scoped>

</style>