<template>
  <div>
    <div v-if="props.withBackButton" class="row items-center" style=" width: 100%; ">
      <div class="col-2 justify-start">
        <BackButton />
      </div>

      <div class="col-8 justify-center">
        <q-separator />
      </div>
    </div>

    <div v-else class="row items-center justify-center" style=" width: 100%; ">
      <div class="col-10 justify-center">
        <q-separator />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BackButton from '@/components/BackButton.vue';

const props = defineProps({
  withBackButton: {
    type    : Boolean,
    default : false
  }
});
</script>

<style scoped>
.q-separator {
  background: radial-gradient(circle, var(--globalAppSecondaryColor2) 80%, #FFFFFF);
}
</style>
