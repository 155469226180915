<template>
	<c-q-btn
  v-if="authStore.currentUserCanSendCampaignToApprove
    && campaignStore.focusedCampaign.iscampanhaedit
    && (
        campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id
        || campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.DRAFT.id
        || campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ON_EDITING.id
    )"
		id="btn-send-campaign-to-approve"
		propBtnLabel="Reenviar para Aprovação"
		:propBtnFunction="onResendFocusedCampaignToApproveBtnClick"
		:propBtnTransparent="false"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>
</template>

<script setup lang="ts">
import { EnumButtonSizes }		from '@/enums/enums';
import { useCampaignStore }		from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages               from '@/utils/messages';

import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';


const campaignStore		= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();
const authStore 			= useAuthStore();

const onResendFocusedCampaignToApproveBtnClick = async () => {
  const comentAux = campaignStore.focusedCampaign.commentadjustment;
  try {
    pagesUtilStore.showLoading = true;   
  
    campaignStore.focusedCampaign.commentadjustment = campaignStore.refRequestedAdjustments;
    
    try{
      await campaignStore.saveFormatAtmImageSlotOnS3();
    }catch(error){
      throw new Error("Erro ao realizar upload da imagem.");
    }

   	await campaignStore.resendToApproveFocusedCampaign();

    campaignStore.refRequestedAdjustments = "";
		
		messages.showSuccessMsg("Campanha reenviada para aprovação com sucesso!");

  } catch (errorResendingCampaignToApprove) {
    messages.showErrorMsg(errorResendingCampaignToApprove.message);
    campaignStore.focusedCampaign.commentadjustment = comentAux;
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};
</script>

<style scoped>

</style>
