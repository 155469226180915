import { defineStore }            from 'pinia';
import { ref, computed }          from 'vue';
import axios                      from 'axios';
import router                     from '@/router/router';
import { USER_ROLES }             from '@/static/constants';
import { CampaignModel }                                                          from '@/models/CampaignModel';
import ApiService                                                                 from '@/utils/ApiService';

export const useAuthStore = defineStore('auth', () => {
  const token = ref(localStorage.getItem("token"));
  const user = ref(localStorage.getItem("user"));
  const isAuth = ref<boolean>(false)

  const currentLoggedInUserEmail = ref<string>(localStorage.getItem('username') as string);
  
  const showMenu = ref<boolean>(false);

  const setCurrentLoggedInUserEmail = (newCurrentLoggedInUserEmail: string) => {
    localStorage.setItem("username", newCurrentLoggedInUserEmail);
    currentLoggedInUserEmail.value = newCurrentLoggedInUserEmail;
  }

  const setToken = (newToken:string) =>{
    localStorage.setItem("token", newToken);
    token.value = newToken;
  }

  const setUser = (newUser:string) =>{
    localStorage.setItem("user", newUser);
    user.value = newUser;
  }

  const setIsAuth = (auth: boolean) =>{
    isAuth.value = auth;
  }

  const setShowMenu = (param: boolean) =>{
    isAuth.value = param;
  }

  async function checkToken(){
    try{
      const tokenAuth = 'Bearer ' + token.value;
      const responseData = await ApiService.get<any>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_VALIDATE_TOKEN);
      
      if(responseData == true) {
        return responseData;
    }
    }catch(error){
      clear();
      router.push('/signin');
      console.error(error);
    }
  }

  function clear(){
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('username');
    isAuth.value = false;
    token.value = '';
    user.value = '';
    currentLoggedInUserEmail.value='';
  }

  const tokenUserRoles = computed(() => {
    const jwt = token.value;

    if (jwt) {
      const jwtData             = jwt.split('.')[1];
      const decodedJwtJsonData  = window.atob(jwtData);
      
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decodedJwtData: { [key: string]: any; 'custom:roles': string[] } = JSON.parse(decodedJwtJsonData);
      const userRoles: string[] = decodedJwtData["custom:roles"];

      return userRoles;
    
    } else {
      return [];
    }
  });
  
  const currentUserRoles = computed(() => {
    return {
      userIsAdmin           : (tokenUserRoles.value.includes(USER_ROLES.ADMIN.value)),
      userIsReadOnly        : (tokenUserRoles.value.includes(USER_ROLES.READ_ONLY.value)),
      userIsContentManager  : (tokenUserRoles.value.includes(USER_ROLES.CONTENT_MANAGER.value)),
      userIsAssessmentTeam  : (tokenUserRoles.value.includes(USER_ROLES.ASSESSMENT_TEAM.value))
    }
  });

  const currentUserCanDeleteCampaign = computed(() => {
    return (
      currentUserRoles.value.userIsAdmin
      || currentUserRoles.value.userIsContentManager
    );
  });

  const currentUserCanViewCampaign = computed(() => {
    return (
      currentUserRoles.value.userIsAdmin
      || currentUserRoles.value.userIsContentManager
      || currentUserRoles.value.userIsAssessmentTeam
      || currentUserRoles.value.userIsReadOnly
    );
  });

  const currentUserCanReviewCampaign = computed(() => {
    return (
      currentUserRoles.value.userIsAdmin
      || currentUserRoles.value.userIsContentManager
    );
  });

  const currentUserCanEditCampaign = computed(() => {
    return (

      currentUserRoles.value.userIsAdmin
      || currentUserRoles.value.userIsContentManager
    );
  });

  const currentUserCanCancelCampaign = computed(() => {
    return (

      currentUserRoles.value.userIsAdmin
      || currentUserRoles.value.userIsContentManager
    );
  });

  const currentUserCanSendCampaignToApprove = computed(() => {
    return (

      currentUserRoles.value.userIsAdmin
      || currentUserRoles.value.userIsContentManager
    );
  });

  const currentUserCanSaveCampaign = computed(() => {
    return (

      currentUserRoles.value.userIsAdmin
      || currentUserRoles.value.userIsContentManager
    );
  });

  const currentUserCanApproveCampaign = computed(() => {
    return (
      currentUserRoles.value.userIsAdmin
      || currentUserRoles.value.userIsAssessmentTeam
    );
  });

  const currentUserCanAskAdjustmentCampaign = computed(() => {
    return (
      currentUserRoles.value.userIsAdmin
      || currentUserRoles.value.userIsAssessmentTeam
    );
  });

  const currentUserCanPublishCampaign = computed(() => {
    return (
      currentUserRoles.value.userIsAdmin
      || currentUserRoles.value.userIsContentManager
    );
  });

  const currentUserCanCreateCampaign = computed(() => {
    return (
      currentUserRoles.value.userIsAdmin
      || currentUserRoles.value.userIsContentManager
    );
  });
  

  return {
    currentLoggedInUserEmail,
    token,
    tokenUserRoles,
    currentUserRoles,
    user,
    isAuth,
    showMenu,

    setCurrentLoggedInUserEmail,
    setToken,
    setUser,
    setIsAuth,
    setShowMenu,

    checkToken,
    clear,

    currentUserCanDeleteCampaign,
    currentUserCanViewCampaign,
    currentUserCanEditCampaign,
    currentUserCanCancelCampaign,
    currentUserCanSendCampaignToApprove,
    currentUserCanSaveCampaign,
    currentUserCanApproveCampaign,
    currentUserCanAskAdjustmentCampaign,
    currentUserCanPublishCampaign,
    currentUserCanReviewCampaign,
    currentUserCanCreateCampaign
  }
})
