export const CONST_CAMPAIGN_STATUS = Object.freeze({
  ON_EDITING: {
		id					: 1,
		description	: "Em Edição"
	},

  DRAFT: {
		id					: 2,
		description	: "Rascunho"
	},

  ON_APPROVAL: {
		id					: 3,
		description	: "Em Aprovação"
	},

	ADJUSTMENT_REQUESTED: {
		id					: 4,
		description	: "Solicitado Ajuste"
	},

  APPROVED: {
		id					: 5,
		description	: "Aprovada"
	},	

  PUBLISHED: {
		id					: 6,
		description	: "Campanha Publicada!"
	},

  CANCELED: {
		id					: 7,
		description	: "Cancelada"
	}
});

export const CONST_STATUS = Object.freeze([
  {
		id					: 1,
		description	: "Em Edição"
	},

  {
		id					: 2,
		description	: "Rascunho"
	},

  {
		id					: 3,
		description	: "Em Aprovação"
	},

	{
		id					: 4,
		description	: "Solicitado Ajuste"
	},

 {
		id					: 5,
		description	: "Aprovada"
	},	

 {
		id					: 6,
		description	: "Campanha Publicada!"
	},

  {
		id					: 7,
		description	: "Cancelada"
	}
]);

export const CONST_CAMPAIGN_ATM_SCREEN_FORMAT_SCREEN_OPTIONS = Object.freeze([
	{
		screenId					: "1",
		screenDescription	: "A1 - Abertura"
	},

	{
		screenId					: "2",
		screenDescription	: "A2 - PropagIF"
	},

	{
		screenId					: "3",
		screenDescription	: "A3 - Retire o seu Dinheiro"
	}
]);

export const USER_ROLES = Object.freeze({
	ADMIN: {
		id		: 0,
		value	: "administrator"
	},

	READ_ONLY: {
		id		: 1,
		value	: "read_only"
	},

	CONTENT_MANAGER: {
		id		: 2,
		value	: "content_manager"
	},

	ASSESSMENT_TEAM: {
		id		: 3,
		value	: "AssessmentTeam"
	}
});

export const ATM_ALLOWED_IMAGE_DIMENSIONS = Object.freeze({
	width		: 640,
	height	: 480
});

export const DEFAULT_IFS_EXPORT_FILENAME = 'Instituições.csv';
export const DEFAULT_PCS_EXPORT_FILENAME = 'PCs_Campanha.csv';
