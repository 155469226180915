<template>
    <div id="div-page-caption-section" class="q-pb-md">
      <span class="page-caption-title">{{ props.pageCaptionTitle }}</span>
      <span class="page-caption-description">{{ props.pageCaptionDescription }}</span>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
  pageCaptionTitle: {
    type    : String,
    default : ''
  },

  pageCaptionDescription: {
    type    : String,
    default : ''
  }
});
</script>

<style scoped>
/* .page-caption-title {
  display: block;
  font-weight: bold;
} */

.page-caption-title {
  display: block;
  font-size: 18px;
  font-weight: 900;
}

.page-caption-description {
  display: block;
  font-size: 18px;
  color: var(--globalAppSecondaryColor3);
}
</style>
