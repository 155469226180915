import script from "./CampaignDevicesSection.vue?vue&type=script&setup=true&lang=ts"
export * from "./CampaignDevicesSection.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon});
