<template>
	<div>
    <div class="row q-pb-xs">
      <span class="midia-app-label-sm" v-if="campaignStore.focusedCampaign.status">Ajustes Solicitados</span>
    </div>

		<div class="row q-py-xs" v-if="campaignStore.focusedCampaign.status">
			<c-q-btn
				id="btn-view-requested-adjustments"
				propBtnLabel="Visualizar"
				:propBtnFunction="onViewRequestedAdjustmentsBtnClick"
				:propBtnTransparent="true"
				:propBtnSize="EnumButtonSizes.SMALL"
			/>
    </div>
	</div>
</template>

<script setup lang="ts">
import { EnumRequestedAdjustmentsModalType, EnumButtonSizes }	from '@/enums/enums';
import { usePagesUtilStore }																	from '@/stores/pagesUtilStore';
import { useCampaignStore }     from '@/stores/campaignStore';

const campaignStore   = useCampaignStore();
const pagesUtilStore = usePagesUtilStore();

const onViewRequestedAdjustmentsBtnClick = () => {
	pagesUtilStore.modalRequestedAdjustmentsModalType	= EnumRequestedAdjustmentsModalType.UPDATE;
	pagesUtilStore.showModalRequestAdjustments				= true;
}
</script>

<style scoped>

</style>