import router from '../router/router'
import { EnumRouteNames }    from '@/enums/enums';
import messages                               from '@/utils/messages';

const redirectIfErrorUnauthorized ={

  clearLocalStorage(){
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('username');
  },

  redirectToLoginPage(){
    this.clearLocalStorage();
    messages.showErrorMsg("Sessão expirada realize login novamente...");
    router.push({ name: EnumRouteNames.ROOT });
  }

}

export default redirectIfErrorUnauthorized;