<template>
  <q-page class="bg-globalAppSecondaryColor4">
    <div class="q-mx-xl q-pb-md">
      <router-view id="router-view-campaign-routes" />
    </div>
  </q-page>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>