<template>
  <div>
    <c-q-separator class="q-pb-lg" />

    <div id="div-pesquisa-campanha" class="row q-pb-lg">      
      <div class="q-pb-xs">
        <span class="midia-app-label-lg">Pesquise uma Campanha</span>
      </div>

      <div class="row fit no-wrap justify-between items-end content-start">
        <div class="col-grow" style=" overflow: auto; ">
          <q-input
            id="searchbar"
            style=" position: relative; "
            v-model="filterTerms"
            color="globalAppPrimaryColor"
            bg-color="globalAppDefaultBackgroundColor"
            outlined
            dense
            placeholder="Digite ou selecione uma Campanha"
            input-class="midia-app-content-lg"
          >
            <template v-slot:prepend>
              <q-icon
                name="search"
                class="q-pa-sm"
                style=" background: var(--globalAppSecondaryColor4); padding: 6px; position: relative; left: -11px; border-right: 1px solid var(--globalAppSecondaryColor2); "
              />
            </template>

            <template v-slot:append>
              <div>
                <div style= "position: relative; ">
                  <q-btn
                    id="btn-show-filterbox"
                    unelevated
                    color="globalAppDefaultBackgroundColor"
                    text-color="globalAppDefaultTextColor"
                    outlined
                    no-caps
                    @click="onFilterBtnClick()"
                  >
                    <template v-slot:default>
                      <q-icon size="16px">
                        <img class="svg-icon" src="@/assets/icons/icon_filter.svg" />
                      </q-icon>

                      <span class="midia-app-label-lg q-pl-sm">Filtrar</span>
                      <!-- <span class="midia-app-label-lg q-pl-sm" :style="(isFilterActive ? ' color: var(--globalAppPrimaryColor) !important; font-style: italic !important; ' : '')">Filtrar</span> -->
                    </template>
                  </q-btn>
                </div>
              </div>
            </template>
          </q-input>
        </div>

        <div class="q-pl-md" style=" overflow: auto; ">
          <c-q-btn
            v-if="authStore.currentUserCanReviewCampaign"
            id="btn-new-campaign"
            propBtnLabel="Nova Campanha"
            :propBtnFunction="onNewCampaignBtnClick"
            :propBtnTransparent="true"
            :propBtnSize="EnumButtonSizes.LARGE_1"
          >
            <template v-slot:default>
              <q-icon size="16px" class="q-pr-sm">
                <img class="svg-icon" src="@/assets/icons/icon_perfil_red.svg" />
              </q-icon>
            </template>
          </c-q-btn>
        </div>
      </div>
    </div>

    <span class="midia-app-label-sm">Campanhas encontradas</span>

    <div id="div-table-campanha" class="row justify-center q-gutter-sm">
      <div style=" width: 100%; ">
        <q-table
          ref="qTableCampanhas"
          :rows="fetchedCampaignsFiltered"
          row-key="id"
          :columns="qTableCampanhasColumns"
          :visible-columns="qTableCampanhasVisibleColumns"
          :pagination="{ rowsPerPage: 10 }"
          :hide-pagination="(fetchedCampaignsFiltered.length <= 10)"
          virtual-scroll
          no-data-label="Nenhum registro encontrado!"
          rows-per-page-label="Linhas por página:"
          selection="multiple"
          v-model:selected="campaignStore.selectedCampaigns"
          :dense="$q.screen.lt.md"
          flat
          separator="horizontal"
          class="midia-app-table"
          @request="onRequest"
        >
          <template v-slot:header="props">
            <q-tr :props="props">
              <q-th
                style="
                  background: var(--globalAppSecondaryColor4);
                  text-align: center;
                  border: 0;
                "
                :auto-width="true"
              >
                <q-checkbox
                  v-model="props.selected"
                  :color="(props.selected ? 'globalAppPrimaryColor' : 'globalAppSecondaryColor2')"
                  :keep-color="(!props.selected)"
                />
              </q-th>
              
              <q-th
                v-for="col in props.cols"
                :key="col.field"
                :props="props"
                style="
                  background: var(--globalAppSecondaryColor4);
                  border: 0;
                "
              >
                <span class="midia-app-title-header-table-lg">{{ col.label }}</span>
              </q-th>
            </q-tr>
          </template>

          <template v-slot:body="props">
            <q-tr class="table-body-rows" :props="props">
              <q-td key="selected" style=" text-align: center; " :auto-width="true">
                <q-checkbox
                  v-model="props.selected"
                  :color="(props.selected ? 'globalAppPrimaryColor' : 'globalAppSecondaryColor2')"
                  :keep-color="(!props.selected)"
                />
              </q-td>

              <q-td key="id" :props="props">
                <div class="row items-center">
                  <span class="midia-app-label-lg">{{ props.row.id }}</span>
                </div>
              </q-td>

              <q-td key="advertiser" :props="props">
                <div class="row items-center no-wrap">
                  <q-icon size="24px">
                    <img class="svg-icon" src="@/assets/icons/icon_advertiser_HiOutlineSpeakerphone.svg" />
                  </q-icon>

                  <span class="midia-app-label-lg q-pl-md">{{ props.row.advertiser }}</span>
                </div>
              </q-td>

              <q-td key="name" :props="props">
                <div class="row items-center">
                  <span class="midia-app-label-lg">{{ props.row.name }}</span>
                </div>
              </q-td>

              <q-td key="format" :props="props">
                <div class="row items-center">
                  <span class="midia-app-label-lg">{{ props.row.format }}</span>
                </div>
              </q-td>

              <q-td key="startDate" :props="props">
                <div class="row items-center">
                  <span class="midia-app-label-lg">{{ props.row.startDateDdMmYyyy }}</span>
                </div>
              </q-td>

              <q-td key="endDate" :props="props">
                <div class="row items-center">
                  <span class="midia-app-label-lg">{{ props.row.endDateDdMmYyyy }}</span>
                </div>
              </q-td>

              <q-td key="status" :props="props">
                <div class="row items-center">
                  <!-- <span :class="'midia-app-label-lg ' + statusTextCssClass(props.row.status)">{{ props.row.status }}</span> -->
                  <span :class="'midia-app-label-lg ' + statusTextCssClass(props.row.status)">{{ props.row.statusDescription }}</span>
                </div>
              </q-td>

              <q-td key="statusIcon" :props="props">
                <div class="row items-center justify-center">
                  <q-icon size="24px">
                    <img v-if="(props.row.status == CONST_CAMPAIGN_STATUS.APPROVED.id)"                   class="svg-icon" src="@/assets/icons/status/icon_status_approved_Vector.svg" />
                    <img v-else-if="(props.row.status == CONST_CAMPAIGN_STATUS.ON_APPROVAL.id)"           class="svg-icon" src="@/assets/icons/status/icon_status_approving_HiClock.svg" />
                    <img v-else-if="(props.row.status == CONST_CAMPAIGN_STATUS.CANCELED.id)"              class="svg-icon" src="@/assets/icons/status/icon_status_canceled_HiXCircle.svg" />
                    <img v-else-if="(props.row.status == CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id)"  class="svg-icon" src="@/assets/icons/status/icon_status_requested_adjustments_HiExclamation.svg" />
                    <img v-else-if="(props.row.status == CONST_CAMPAIGN_STATUS.ON_EDITING.id)"  class="svg-icon" src="@/assets/icons/status/icon_status_edit.svg" />
                    <img v-else-if="(props.row.status == CONST_CAMPAIGN_STATUS.DRAFT.id)"  class="svg-icon" src="@/assets/icons/status/icon_status_rascunho.svg" />
                    <img v-else-if="(props.row.status == CONST_CAMPAIGN_STATUS.PUBLISHED.id)"  class="svg-icon" src="@/assets/icons/status/icon_status_published.svg" />
                  </q-icon>
                </div>
              </q-td>

              <q-td>
                <div class="div-container-action-icons">
                  <div>
                    <q-btn
                      unelevated
                      color="white"
                      outlined
                      text-color="black"
                      no-caps
                    >
                    
                      <template v-slot:default>
                        <q-icon size="24px" @click="onActionsBtnClick(props.row)">
                          <img class="svg-icon" src="@/assets/icons/icon_IoEllipsisVerticalCircleOutline.svg" />
                        </q-icon>
                        <q-popup-proxy>
                          <q-banner>
                            <q-card style=" width: 127px; padding: 8px; ">
                              <div style=" display: flex; flex-direction: column; gap: 10px; ">
                                <BtnViewFocusedCampaign
                                  style=" width: 100%; "
                                  :propBtnSize="EnumButtonSizes.SMALL"
                                />

                               <!-- <BtnEditFocusedCampaign
                                  style=" width: 100%; "
                                  :propBtnSize="EnumButtonSizes.SMALL"
                                /> -->
                                
                                <BtnDeleteFocusedCampaign
                                  style=" width: 100%; "
                                  :propBtnSize="EnumButtonSizes.SMALL"
                                />
                              </div>
                            </q-card>
                            
                          </q-banner>
                        </q-popup-proxy>
                      </template>
                    </q-btn>
                  </div>
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>

    <c-q-separator class="q-my-md" />

    <CampaignActionButtonsSection
      :propShowBtnDeleteSelectedCampaigns="true"
    />

    <ModalPageCampaignsTableActions
      :propShowBtnViewFocusedCampaign="showBtnViewFocusedCampaign"
      :propShowBtnDeleteFocusedCampaign="showBtnDeleteFocusedCampaign"
    />

   <!-- <ModalPageCampaignsTableActions
      :propShowBtnViewFocusedCampaign="showBtnViewFocusedCampaign"
      :propShowBtnEditFocusedCampaign="showBtnEditFocusedCampaign"
      :propShowBtnDeleteFocusedCampaign="showBtnDeleteFocusedCampaign"
    /> -->
    
    <ModalDeleteCampaignConfirmation />
    <ModalFilterCampaign />
  </div>
</template>

<script setup lang="ts">
import CampaignActionButtonsSection           from '@/components/campaign/CampaignActionButtonsSection.vue';
import ModalPageCampaignsTableActions         from '@/views/modals/ModalPageCampaignsTableActions.vue';
import ModalDeleteCampaignConfirmation        from '@/views/modals/ModalDeleteCampaignConfirmation.vue';
import ModalFilterCampaign                    from '@/views/modals/ModalFilterCampaign.vue';
import { ref, computed, onMounted }           from 'vue';
import { useQuasar }                          from 'quasar';
import { campaignInterface }                  from '@/types/types.d';
import { CampaignModel }                      from '@/models/CampaignModel';
import { quasarCustomQTableColumns }          from '@/types/quasarCustomQTableColumn';
import { useCampaignStore }                   from '@/stores/campaignStore';
import { usePagesUtilStore }                  from '@/stores/pagesUtilStore';
import { useRouter }                          from 'vue-router';
import { EnumRouteNames, EnumButtonSizes }    from '@/enums/enums';
import { CONST_CAMPAIGN_STATUS }              from '@/static/constants';
import { useAuthStore }					from '@/stores/authStore'
import messages                               from '@/utils/messages';
import BtnViewFocusedCampaign		from '@/components/campaign/actionButtons/BtnViewFocusedCampaign.vue';
//import BtnEditFocusedCampaign		from '@/components/campaign/actionButtons/BtnEditFocusedCampaign.vue';
import BtnDeleteFocusedCampaign	from '@/components/campaign/actionButtons/BtnDeleteFocusedCampaign.vue';

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();
const authStore 			= useAuthStore();

const $q              = useQuasar();
const router          = useRouter();

const filterTerms     = ref('');

const props = defineProps({
  propShowBtnViewFocusedCampaign: {
    type      : Boolean,
    required  : true
  },

 /* propShowBtnEditFocusedCampaign: {
    type      : Boolean,
    required  : true
  },*/

  propShowBtnDeleteFocusedCampaign: {
    type      : Boolean,
    required  : true
  }
});

const qTableCampanhasColumns:quasarCustomQTableColumns[] = [
  {
    name        : 'id',
    label       : 'ID',
    field       : 'id',
    align       : 'center'
  },

  {
    name        : 'advertiser',
    label       : 'Anunciante',
    field       : 'advertiser',
    align       : 'left'
  },

  {
    name        : 'name',
    label       : 'Campanha',
    field       : 'name',
    align       : 'left',
    style       : 'width: auto;',
    headerStyle : 'width: auto;'
  },

  {
    name        : 'format',
    label       : 'Formato',
    field       : 'format',
    align       : 'left'
  },

  {
    name        : 'startDate',
    label       : 'Início',
    field       : 'startDate',
    align       : 'left',
    sortOrder   : 'da'
  },

  {
    name        : 'endDate',
    label       : 'Fim',
    field       : 'endDate',
    align       : 'left'
  },
  {
    name        : 'status',
    label       : 'Status',
    field       : 'status',
    align       : 'center',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    format      : (val, row) => {
      switch (val) {
        case CONST_CAMPAIGN_STATUS.APPROVED.id:
          return "status-approved";
          
        case CONST_CAMPAIGN_STATUS.ON_APPROVAL.id:
          return "status-approving";
          
        case CONST_CAMPAIGN_STATUS.CANCELED.id:
          return "status-canceled";
          
        case CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id:
          return "status-adjustments-requested";
          
        default:
          return "";
      }
    },
  },

  {
    name        : 'statusIcon',
    label       : '',
    field       : 'statusIcon',
    align       : 'center'
  },

  {
    name        : 'acoes',
    label       : 'Ações',
    field       : 'acoes',
    align       : 'center'
  }
];

const qTableCampanhasVisibleColumns = [
  'advertiser',
  'name',
  'format',
  'startDate',
  'endDate',
  
  'status',
  'statusIcon',
  'acoes'
];

const onFilterBtnClick = () => {
  pagesUtilStore.showModalFilterCampaign = true;
};

const isFilterActive = computed(() => {
  return Boolean(
    campaignStore.campaignFilter.filterCampaign.advertiser       
    || campaignStore.campaignFilter.filterCampaign.agency           
    || campaignStore.campaignFilter.filterCampaign.name             
    || campaignStore.campaignFilter.filterCampaign.startDateDdMmYyyy
    || campaignStore.campaignFilter.filterCampaign.endDateDdMmYyyy  
    || campaignStore.campaignFilter.filterCampaign.format           
    || campaignStore.campaignFilter.filterCampaign.status
    || campaignStore.campaignFilter.filterCampaignFormatATMScreen.screen
    || (campaignStore.campaignFilter.filterCampaignFormatATMScreen.pcs?.length > 0)
    || (campaignStore.campaignFilter.filterCampaignFormatATMScreen.institutions?.length > 0)
  ).valueOf();
});

const qTableCampanhasPagination = ref({
  sortBy      : null,
  descending  : true,
  page        : 1,
  rowsPerPage : 5,
  /* modelValue  : 1, */
  max         : 100
});

const onRequest = async () => {
  console.log('onRequest');
};

const onActionsBtnClick = (row: campaignInterface) => {
  campaignStore.focusedCampaign          = new CampaignModel(row);
  campaignStore.focusedCampaignSnapshot  = Object.freeze(new CampaignModel(row));

  if (typeof campaignStore.focusedCampaign.id !== "undefined") {
    sessionStorage.setItem('sessionPersistentFocusedCampaignId', campaignStore.focusedCampaign.id.toString());
  }

  // pagesUtilStore.showModalPageCampaignsTableActions = true;
};

const onNewCampaignBtnClick = () => {
  router.push({ name: EnumRouteNames.CAMPAIGN_NEW });
  campaignStore.resetState();
};

const statusTextCssClass = ((statusId: number) => {
  switch (statusId) {
    case CONST_CAMPAIGN_STATUS.APPROVED.id:
      return "status-approved";
      
    case CONST_CAMPAIGN_STATUS.ON_APPROVAL.id:
      return "status-approving";
      
    case CONST_CAMPAIGN_STATUS.CANCELED.id:
      return "status-canceled";
      
    case CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id:
      return "status-adjustments-requested";
    
    case CONST_CAMPAIGN_STATUS.ON_EDITING.id:
      return "status-on-editing";
    
    case CONST_CAMPAIGN_STATUS.DRAFT.id:
      return "status-draft";
    
    case CONST_CAMPAIGN_STATUS.PUBLISHED.id:
      return "status-published";
      
    default:
      return "";
  }
});

const fetchedCampaignsFiltered = computed(() => {
  if (filterTerms.value) {
    return campaignStore.campaigns.filter(c => (
      c.name?.toLowerCase().includes(filterTerms.value.toLowerCase())
      || c.advertiser?.toLowerCase().includes(filterTerms.value.toLowerCase())
      || c.format?.toLowerCase().includes(filterTerms.value.toLowerCase())
      || c.startDateDdMmYyyy?.includes(filterTerms.value.toLowerCase())
      || c.endDateDdMmYyyy?.includes(filterTerms.value.toLowerCase())
      || c.statusDescription?.toLowerCase().includes(filterTerms.value.toLowerCase())
    ));

  } else {
    return campaignStore.campaigns;
  }
});

const showBtnViewFocusedCampaign = computed(() => {
  // TODO: montar logica...
  return true;
});

const showBtnEditFocusedCampaign = computed(() => {
  // TODO: montar logica...
  return true;
});

const showBtnDeleteFocusedCampaign = computed(() => {
  // TODO: montar logica...
  return true;
});

onMounted(async () => {
  campaignStore.resetState();

  try {
    pagesUtilStore.showLoading = true;
  
    await campaignStore.fetchCampaigns();

  } catch (errorFetchingCampaigns) {
    if(errorFetchingCampaigns.response.status == 401){
      console.error("Sessão Expirada");
      pagesUtilStore.showLoading = false;
    }else{
      messages.showErrorMsg("Erro ao tentar obter a lista de campanhas...");
      pagesUtilStore.showLoading = false;
    }
  } finally {
    pagesUtilStore.showLoading = false;
  }

  onRequest();
});
</script>

<style scoped>
#btn-show-filterbox {
   margin-right: -11px;
   height: 97%;
   border-left: 1px solid rgb(186, 186, 186);
}

.div-container-action-icons {
  display: flex;
  /* padding-top: 6px; */
  justify-content: center;
  align-items: center;
}

.status-approved {
  color: var(--globalSuccessMsgColor) !important;
}

.status-approving {
  color: var(--globalAppSecondaryColor1) !important;
}

.status-canceled {
  color: var(--globalErrorMsgColor) !important;
}

.status-adjustments-requested {
  color: var(--globalWarningMsgColor) !important;
}

.status-on-editing {
  color: var(--globalAppSecondaryColor6	) !important;
}

.status-draft {
  color: var(--globalAppSecondaryColor7) !important;
}

.status-published {
  color: var(--globalAppSecondaryColor8) !important;
}

.label-status {
  padding-left: 30px;
}
</style>