/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import utils from './utils'


interface axiosCallParamsInterface {
  method              : string;
  apiEndpoint         : string;
  apiRoute            : string;
  params?             : object;
  body?               : object;
  additionalHeaders?  : object;
}

class ApiService {

  constructor() {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          utils.redirectToLoginPage();
        }
        return Promise.reject(error);
      }
    );
  }

  private async axiosCall<T>(axiosCallParams: axiosCallParamsInterface): Promise<T> {
    const axiosConfig: AxiosRequestConfig = {
      method  : axiosCallParams.method,
      url     : `${axiosCallParams.apiEndpoint}/${axiosCallParams.apiRoute}`,
      params  : axiosCallParams.params,
      data    : axiosCallParams.body,
      headers : {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        ...axiosCallParams.additionalHeaders
      }
    };

    const response: AxiosResponse<T> = await axios(axiosConfig);

    return response.data;
  }

  public async get<T>(apiEndpoint: string, apiRoute: string, params?: any): Promise<T> {
    try {
      return await this.axiosCall<T>({
        method: 'get',
        apiEndpoint,
        apiRoute,
        params
      });

    } catch (error) {
      console.error(`Error in GET ${apiEndpoint}/${apiRoute}: `, error);
      
      throw error;
    }
  }

  public async post<T>(apiEndpoint: string, apiRoute: string, body: any, additionalHeaders?: any): Promise<T> {
    try {
      return await this.axiosCall<T>({
        method: 'post',
        apiEndpoint,
        apiRoute,
        body,
        additionalHeaders
      });

    } catch (error) {
      console.error(`Error in POST ${apiEndpoint}/${apiRoute}: `, error);
      
      throw error;
    }
  }

  public async put<T>(apiEndpoint: string, apiRoute: string, body?: any, params?: any): Promise<T> {
    try {
      return await this.axiosCall<T>({
        method: 'put',
        apiEndpoint,
        apiRoute,
        body,
        params
      });

    } catch (error) {
      console.error(`Error in PUT ${apiEndpoint}/${apiRoute}: `, error);
      
      throw error;
    }
  }

  public async delete<T>(apiEndpoint: string, apiRoute: string, params?: any): Promise<T> {
    try {
      return await this.axiosCall<T>({
        method: 'delete',
        apiEndpoint,
        apiRoute,
        params
      });

    } catch (error) {
      console.error(`Error in DELETE ${apiEndpoint}/${apiRoute}: `, error);
      
      throw error;
    }
  }
}

export default new ApiService();
