<template>
	<c-q-btn
		v-if="authStore.currentUserCanCancelCampaign 
		&& (campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id 
		|| campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.APPROVED.id
		||
		(campaignStore.focusedCampaign.status == CONST_CAMPAIGN_STATUS.PUBLISHED.id
			&& diferancaEntreDatas(campaignStore.focusedCampaign.startDate)
		))"
		id="btn-cancel-focused-campaign"
		propBtnLabel="Cancelar"
		:propBtnFunction="onCancelFocusedCampaignBtnClick"
		:propBtnTransparent="true"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>
</template>

<script setup lang="ts">
import { EnumButtonSizes }		from '@/enums/enums';
import { useCampaignStore } 	from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages								from '@/utils/messages';
import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';
import moment from 'moment';

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();
const authStore 			= useAuthStore();

const diferancaEntreDatas  = ( data : string | undefined ) => {

const hoje = moment();

data = campaignStore.focusedCampaign.startDate;

const data1 = moment(data);

const diferenca = data1.diff(hoje, 'days');

return diferenca >= 3;

};

const onCancelFocusedCampaignBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;
  
		await campaignStore.cancelFocusedCampaign();

		messages.showSuccessMsg("Campanha cancelada com sucesso!");

  } catch (errorEditingCampaign) {
    messages.showErrorMsg("Erro ao tentar cancelar a campanha...");
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
};
</script>

<style scoped>

</style>
