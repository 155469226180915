<template>
	<q-btn
		id="btn-close-modal"
		icon="close"
		size="12px"
		flat
		@click="onClearFilterBtnClick"
		round
		dense
		v-close-popup
	/>
</template>

<script setup lang="ts">
import { useCampaignStore } 										from '@/stores/campaignStore';
const campaignStore 	= useCampaignStore();

const onClearFilterBtnClick = () => {
	campaignStore.resetCampaignFilter();
};

</script>

<style scoped>

</style>