<template>
  <div>
    <c-q-separator class="q-pb-lg" :withBackButton="true" />

    <PageCaption
      pageCaptionTitle="Editar Campanha"
      pageCaptionDescription=""
    />

    <CampaignIdentificationSectionView :propCampaignToView="campaignStore.focusedCampaignSnapshot" />

    <c-q-separator class="q-pt-lg q-pb-lg" />

    <CampaignIdentificationSectionEdit />

    <CampaignFormatSectionEdit />

    <c-q-separator class="q-my-md" />

    <CampaignActionButtonsSection
      :propShowBtnReviewFocusedCampaign="true"
    />
  </div>
</template>

<script setup lang="ts">
import PageCaption	                      from '@/components/PageCaption.vue';
import CampaignIdentificationSectionView  from '@/components/campaign/CampaignIdentificationSectionView.vue';
import CampaignIdentificationSectionEdit  from '@/components/campaign/CampaignIdentificationSectionEdit.vue';
import CampaignFormatSectionEdit          from '@/components/campaign/CampaignFormatSectionEdit.vue';
import CampaignActionButtonsSection       from '@/components/campaign/CampaignActionButtonsSection.vue';
import { useCampaignStore }               from '@/stores/campaignStore';
import {onMounted}                           from 'vue'
import { useRouter } from 'vue-router';

const campaignStore = useCampaignStore();
const router = useRouter();

onMounted(() => {
      const from = sessionStorage.getItem('from');
      // Se a página foi recarregada e o histórico foi mantido, redirecione para a página inicial
      if (!from) {
        router.push({ name: 'home' });
      } else {
        sessionStorage.removeItem('from');
      }
    });

router.beforeEach((to, from, next) => {
  sessionStorage.setItem('from', 'true'); // Mantém o histórico

  next(); // Continua a navegação normalmente
});


</script>

<style scoped>

</style>