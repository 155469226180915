<template>
	<div class="row fit no-wrap items-center content-start">
		<div class="q-px-lg" @click="onImageDivClick()">
			<!-- <img class="cursor-pointer" src="@/assets/images/imagem_exemplo_campanha_mcdia_feliz.png" alt="img_campanha" style=" width: 200px; height: 112.5px; object-fit: contain; " /> -->
			<img class="cursor-pointer" :src="computedImgUrl" alt="img_campanha" style=" width: 200px; height: 112.5px; object-fit: contain; " />
		</div>

		<div class="q-pr-sm">
			<div class="row">
				<span class="midia-app-label-sm">Arquivo da Campanha</span>
			</div>

			<div class="row q-pb-sm">
				<span class="midia-app-label-lg">{{ computedCampaignJsonSlot.imageFileName }}</span>
			</div>

			<div class="row">
				<div class="col-6 col-md-6 q-pr-sm">
					<div class="row">
						<span class="midia-app-label-sm">Dimensões</span>
					</div>

					<div class="row">
						<span class="midia-app-label-md">{{ `${computedCampaignJsonSlot.imageWidth} x ${computedCampaignJsonSlot.imageHeight}px` }}</span>
					</div>
				</div>

				<div class="col-6 col-md-6 q-pl-sm">
					<div class="row">
						<span class="midia-app-label-sm">Tamanho</span>              
					</div>

					<div class="row">
						<span class="midia-app-label-md">{{ (computedCampaignJsonSlot.imageSize ? `${Math.ceil(computedCampaignJsonSlot.imageSize / 1024)} KB` : "") }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="q-ml-lg q-mb-sm" style=" align-self: flex-end; " v-if="campaignStore.focusedCampaign.status">
			<c-q-btn 
				id="btn-download-campaign-image"
				propBtnLabel="Download"
				:propBtnFunction="onDownloadCampaignImgBtnClick"
				:propBtnTransparent="true"
				:propBtnSize="EnumButtonSizes.SMALL"
			/>
		</div>

		<ModalImagePreview
			:propImgObjectUrl="computedCampaignJsonSlot.imageObjectUrl ? computedCampaignJsonSlot.imageObjectUrl : ''"
			:propShowDownloadButton="false"
		/>
	</div>
</template>

<script setup lang="ts">
import ModalImagePreview							from '@/views/modals/ModalImagePreview.vue';
import { EnumButtonSizes,}						from '@/enums/enums';
import { useCampaignStore }   				from '@/stores/campaignStore';
import { usePagesUtilStore }					from '@/stores/pagesUtilStore';
import { computed }           				from 'vue';
import { campaignJsonSlotInterface }	from '@/types/types';

const campaignStore         = useCampaignStore();
const pagesUtilStore        = usePagesUtilStore();

const onImageDivClick = () => {
  pagesUtilStore.showModalImagePreview = true;
};

const onDownloadCampaignImgBtnClick = () => {
	const urlInfo = campaignStore.focusedCampaign.formatAtmScreenImageSlotS3Url();
	if(urlInfo){
		const {imageUrl} = urlInfo
		window.location.href = imageUrl;
	}else {
  // Handle the case where the URL is undefined
  console.error("URL is undefined");
}
};

const computedCampaignJsonSlot = computed(() => {
	if (campaignStore.focusedCampaign.slot1) {
		return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot1);

	} else if (campaignStore.focusedCampaign.slot2) {
		return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot2);

	} else if (campaignStore.focusedCampaign.slot3) {
		return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot3);

	} else {
		return {
			imageObjectUrl	: "",
			imageS3Url      : undefined,
			imageFileName 	: "",
			imageWidth			: undefined,
			imageHeight			: undefined,
			imageSize				: undefined
		};
	}
});

const computedImgUrl = computed(() => {
	if (campaignStore.focusedCampaign.id) {

		return campaignStore.focusedCampaign.formatAtmScreenImageSlotS3Url()?.imageUrl;

	} else {
		if (campaignStore.focusedCampaign.slot1) {
			return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot1).imageObjectUrl;

		} else if (campaignStore.focusedCampaign.slot2) {
			return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot2).imageObjectUrl;

		} else if (campaignStore.focusedCampaign.slot3) {
			return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot3).imageObjectUrl;

		} else {
			return {
				imageObjectUrl	: "",
				imageS3Url      : undefined,
				imageFileName 	: "",
				imageWidth			: undefined,
				imageHeight			: undefined,
				imageSize				: undefined
			};
		}
	}
});
</script>

<style scoped>

</style>