<template>
  <!-- <q-page class="bg-globalAppDefaultBackgroundColor"> -->
    <div class="flex items-center justify-center outline-red" style=" min-height: inherit; background-color: var(--globalAppDefaultBackgroundColor); ">
      <!-- <div class="row no-wrap items-center justify-center full-height q-my-auto"> -->
      <div class="row no-wrap items-center">
        <div class="col-6 col-md-6 flex flex-grow items-center justify-end q-pr-xl">
          <img src="@/assets/logos/logo_TecBan_MidiaFlow.svg" width="410">
        </div>

        <div class="col-6 col-md-6 justify-start q-pl-xl q-py-sm" style=" border-left: 1px solid var(--globalAppSecondaryColor2); ">
          <div class="column no-wrap items-start q-pl-sm" style=" width: 400px; ">
            <div class="flex full-width q-pb-lg">
              <span class="midia-app-title-login-xl q-pb-xs">Faça o login em sua conta</span>
              <!-- <span class="midia-app-label-lg">Digite seu e-mail e senha</span> -->
            </div>

            <!-- <div class="full-width q-pb-md">
              <div class="q-pb-sm">
                <span class="midia-app-label-lg">E-mail</span>
              </div>

              <q-input
                v-model="userEmail"
                type="text"
                maxlength="255"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                outlined
                dense
              />
            </div> -->

            <!-- <div class="full-width q-pb-md">
              <div class="q-pb-sm">
                <span class="midia-app-label-lg">Senha</span>
              </div>

              <q-input
                v-model="userPassword"
                type="text"
                maxlength="255"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                outlined
                dense
              />

              <div style=" margin-left: -8px; ">
                <q-toggle
                  v-model="rememberMeToggle"
                  color="globalAppPrimaryColor"
                  size="sm"
                >
                  <template v-slot:default>
                    <span class="midia-app-label-lg">Lembrar</span>
                  </template>
                </q-toggle>
              </div>
            </div> -->

            <!-- <div class="col-grow full-width q-pb-lg"> -->
            <div class="col-grow full-width q-pb-xs">
              <c-q-btn
                id="btn-login"
                style=" width: 100%; "
                propBtnLabel="Entrar"
                :propBtnFunction="onLoginBtnClick"
                :propBtnTransparent="false"
                :propBtnSize="EnumButtonSizes.LARGE_2"
              />
            </div>

            <!-- <div class="self-center">
              <span class="midia-app-label-lg">Não possui uma conta?</span>
              <span class="midia-app-label-register-here-lg q-pl-sm">Cadastre-se aqui</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  <!-- </q-page> -->
</template>

<script setup lang="ts">
import { EnumButtonSizes }  from '@/enums/enums';

const simularLogin = () => {
  var url = `${process.env.VUE_APP_COGNITO_URL_TOKEN}/oauth2/authorize?client_id=${process.env.VUE_APP_APP_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${process.env.VUE_APP_AUTH_CALLBACK_URI}`
  // Use the Vue Router to navigate to the SAML login page
  window.location.href= url;
  // authStore.currentLoggedInUserEmail = userEmail.value;
}

const onLoginBtnClick = () => {
  simularLogin();
}
</script>

<style scoped>
.q-toggle {
  width: 200px !important;
}
</style>