<template>
	<div>
		<q-dialog
			v-model="pagesUtilStore.showModalFilterCampaign"
			:no-backdrop-dismiss="true"
		>
			<q-card class="midia-app-padding-modal" style=" width: 500px; max-width: 80vw; ">
				<div>
					<c-modal-title
						propModalTitle="Filtro de Campanhas"
						propModalDescription="Consulte e gerencie as campanhas cadastradas"
					/>
					<div class="q-pb-md">
						<div class="row q-pb-xs">
								<span class="midia-app-label-modal-sm">Anunciante</span>
						</div>

						<div class="row col">
							<q-input
								v-model="campaignStore.campaignFilter.filterCampaign.advertiser"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
								outlined
								dense
								placeholder="Informe um Anunciante"
								input-class="midia-app-content-lg"
								style=" width: 100%; "
							/>
						</div>
					</div>

					<div class="q-pb-md">
						<div class="row q-pb-xs">
								<span class="midia-app-label-modal-sm">Agência</span>
						</div>

						<div class="row col">
							<q-input
								v-model="campaignStore.campaignFilter.filterCampaign.agency"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
								outlined
								dense
								placeholder="Informe uma Agência"
								input-class="midia-app-content-lg"
								style=" width: 100%; "
							/>
						</div>
					</div>

					<div class="q-pb-md">
						<div class="row q-pb-xs">
								<span class="midia-app-label-modal-sm">Campanha</span>
						</div>

						<div class="row col">
							<q-input
								v-model="campaignStore.campaignFilter.filterCampaign.name"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
								outlined
								dense
								placeholder="Informe uma Campanha"
								input-class="midia-app-content-lg"
								style=" width: 100%; "
							/>
						</div>
					</div>

					<div class="row col q-pb-md">
						<div class="row q-pb-xs">
								<span class="midia-app-label-modal-sm">Status</span>
						</div>
						<q-select
							v-model="campaignStore.campaignFilter.filterCampaign.status"
							:options="optionsStatus"
							outlined
							option-label="description"
							option-value="id"
							map-options
							emit-value
							dense
							color="globalAppPrimaryColor"
							style=" width: 100%; "
							:label-slot="!campaignStore.campaignFilter.filterCampaign.status"
						>
							<template v-slot:label>
								<span class="midia-app-placeholder-md">Selecione um Status</span>
							</template>

							<!-- <template v-slot:selected>
								<span class="midia-app-content-lg">{{ campaignStore.campaignFilter.filterCampaign.status }}</span>
							</template> -->

							<template v-slot:option="{ itemProps, opt }">
								<q-item v-bind="itemProps">
								<q-item-section>
								<q-item-label>{{ sanitizeHTML(opt.description) }}</q-item-label>
								</q-item-section>
								</q-item>
							</template>
						</q-select>
					</div>

					<div class="q-pb-md">
						<div class="row q-pb-xs">
								<span class="midia-app-label-modal-sm">Período</span>
						</div>

						<div class="flex items-center">
							<div style=" width: 160px; ">
								<q-input
									style=" outline: 1px solid var(--globalAppSecondaryColor2); "
									v-model="campaignStore.campaignFilter.filterCampaign.startDateDdMmYyyy"
									mask="##/##/####"
									bg-color="globalAppDefaultBackgroundColor"
									borderless
									dense
									input-class="midia-app-content-lg"
									@update:model-value="updateStartDate"
								>
									<template v-slot:prepend>
										<q-icon class="cursor-pointer q-pa-sm q-mr-sm" style=" background: var(--globalAppPrimaryColor); ">
											<img class="svg-icon" src="@/assets/icons/icon_HiOutlineCalendar.svg" />
										
											<q-popup-proxy ref="qPopupProxyStartDate" cover transition-show="scale" transition-hide="scale">
												<q-date
													v-model="campaignStore.campaignFilter.filterCampaign.startDate"
													mask="YYYY-MM-DD"
													minimal
													:default-year-month="`${startDateDefaultYearMonth}`"
													color="globalAppPrimaryColor"
													@update:model-value="updateStartDateDdMmYyyy"
												>
													<div class="row items-center justify-end">
														<ButtonCloseModal />
													</div>
												</q-date>
											</q-popup-proxy>
										</q-icon>
									</template>
								</q-input>
							</div>

							<span class="midia-app-label-lg q-px-md">até</span>

							<div style=" width: 160px; ">
								<q-input
									style=" outline: 1px solid var(--globalAppSecondaryColor2); "
									v-model="campaignStore.campaignFilter.filterCampaign.endDateDdMmYyyy"
									mask="##/##/####"
									bg-color="globalAppDefaultBackgroundColor"
									borderless
									dense
									input-class="midia-app-content-lg"
									@update:model-value="updateEndDate"
								>
									<template v-slot:prepend>
										<q-icon class="cursor-pointer q-pa-sm q-mr-sm" style=" background: var(--globalAppPrimaryColor); ">
											<img class="svg-icon" src="@/assets/icons/icon_HiOutlineCalendar.svg" />
										
											<q-popup-proxy ref="qPopupProxyEndDate" cover transition-show="scale" transition-hide="scale">
												<q-date
													v-model="campaignStore.campaignFilter.filterCampaign.endDate"
													mask="YYYY-MM-DD"
													minimal
													:options="endDateOptions"
													:default-year-month="`${endDateDefaultYearMonth}`"
													color="globalAppPrimaryColor"
													@update:model-value="updateEndDateDdMmYyyy"
												>
													<div class="row items-center justify-end">
														<ButtonCloseModal />
													</div>
												</q-date>
											</q-popup-proxy>
										</q-icon>
									</template>
								</q-input>
							</div>
						</div>
					</div>
					
					<ModalFilterCampaignCampaignFormat class="q-pb-sm" />
					
					<ModalFilterCampaignCampaignFormatSection class="q-pb-sm" />

					<c-q-separator class="q-pt-sm q-pb-lg" />
					
					<div class="row fit flex no-wrap justify-between q-pb-xs" style=" width: 100%; ">
						<div class="q-pr-sm">
							<c-q-btn
								id="btn-clear-filter"
								propBtnLabel="Limpar Filtros"
								:propBtnFunction="onClearFilterBtnClick"
								:propBtnTransparent="true"
								:propBtnSize="EnumButtonSizes.LARGE_1"
							/>
						</div>

						<div class="col col-grow q-pl-sm">
							<c-q-btn
								id="btn-fetch-campaigns"
								style=" width: 100%; "
								propBtnLabel="Buscar"
								:propBtnFunction="onFetchCampaignsBtnClick"
								:propBtnTransparent="false"
								:propBtnSize="EnumButtonSizes.LARGE_1"
							/>
						</div>
						
						<q-inner-loading
						:showing="pagesUtilStore.showLoadingModal"
						label="aguarde..." 
						/>

					</div>
				</div>
			</q-card>
		</q-dialog>
	</div>
</template>

<script setup lang="ts">
import ButtonCloseModal													from '@/components/ButtonCloseModal.vue'
import ModalFilterCampaignCampaignFormat				from '@/components/campaign/ModalFilterCampaignCampaignFormat.vue'
import ModalFilterCampaignCampaignFormatSection	from '@/components/campaign/ModalFilterCampaignCampaignFormatSection.vue'
import { EnumButtonSizes }											from '@/enums/enums';
import { useCampaignStore } 										from '@/stores/campaignStore';
import { usePagesUtilStore }										from '@/stores/pagesUtilStore';
import { ref, computed }              					from 'vue';
import { convertDateToStringYyyyMmDd }					from '@/utils/commonUtils';
import { QPopupProxy }                					from 'quasar';
import messages                               	from '@/utils/messages';
import { CONST_STATUS }	from '@/static/constants';

const campaignStore 	= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();

const qPopupProxyStartDate  : { value: QPopupProxy | null } = ref(null);
const qPopupProxyEndDate    : { value: QPopupProxy | null } = ref(null);

const onClearFilterBtnClick = () => {
	campaignStore.resetCampaignFilter();
};

const sanitizeHTML = (html:any) => {
      return html; // For demonstration purpose only, please sanitize the HTML properly
    }

const validateFields = () => {
	let fieldsOk = true;

	if (
		campaignStore.campaignFilter.filterCampaign.endDate
		&& campaignStore.campaignFilter.filterCampaign.startDate
		&& (campaignStore.campaignFilter.filterCampaign.endDate <= campaignStore.campaignFilter.filterCampaign.startDate)
	) {
		messages.showWarningMsg("Data final deve ser maior que a inicial.");

		fieldsOk = false;
	}

	if(campaignStore.campaignFilter.filterCampaign.format 
	&& !campaignStore.campaignFilter.filterCampaign.startDate 
	&& !campaignStore.campaignFilter.filterCampaign.endDate ){

		messages.showWarningMsg("Escolha ao menos um período para a consulta");

		fieldsOk = false;
	}

	if(campaignStore.campaignFilter.filterCampaign.startDate && !campaignStore.campaignFilter.filterCampaign.endDate 
	|| campaignStore.campaignFilter.filterCampaign.endDate && !campaignStore.campaignFilter.filterCampaign.startDate ){

		messages.showWarningMsg("Obrigatório informar o Inicio e Fim do período.");

		fieldsOk = false;
	}

	return fieldsOk;
};

const onFetchCampaignsBtnClick = async () => {
  try {
    pagesUtilStore.showLoadingModal = true;

		if (validateFields()) {
			await campaignStore.fetchCampaigns();
		
			pagesUtilStore.showModalFilterCampaign = false;
		}

  } catch (errorFetchingCampaigns) {
    messages.showErrorMsg("Erro ao tentar obter a lista de campanhas...");
		pagesUtilStore.showLoadingModal = false;
  
  } finally {
    pagesUtilStore.showLoadingModal = false;
  }
};

/* const handleUpdateAdvertiser = (value: string) => {
	console.log("handleUpdate - value:", value);
	campaignStore.campaignFilter.filterCampaign.advertiser = value;
} */

const updateStartDate = () => {
  if (campaignStore.campaignFilter.filterCampaign.startDateDdMmYyyy) {
    campaignStore.campaignFilter.filterCampaign.startDate = campaignStore.campaignFilter.filterCampaign.startDateDdMmYyyy.split('/').reverse().join('-');
  }
};

const updateStartDateDdMmYyyy = () => {
  if (campaignStore.campaignFilter.filterCampaign.startDate) {
    campaignStore.campaignFilter.filterCampaign.startDateDdMmYyyy = campaignStore.campaignFilter.filterCampaign.startDate.split('-').reverse().join('/');
  }

  if (qPopupProxyStartDate.value) {
    qPopupProxyStartDate.value.hide();
  }
};

const updateEndDate = () => {
  if (campaignStore.campaignFilter.filterCampaign.endDateDdMmYyyy) {
    campaignStore.campaignFilter.filterCampaign.endDate = campaignStore.campaignFilter.filterCampaign.endDateDdMmYyyy.split('/').reverse().join('-');
  }
};

const updateEndDateDdMmYyyy = () => {
  if (campaignStore.campaignFilter.filterCampaign.endDate) {
    campaignStore.campaignFilter.filterCampaign.endDateDdMmYyyy = campaignStore.campaignFilter.filterCampaign.endDate.split('-').reverse().join('/');
  }

  if (qPopupProxyEndDate.value) {
    qPopupProxyEndDate.value.hide();
  }
};

const optionsStatus				= computed(() => {
	return CONST_STATUS;
});

const endDateMinDateAllowedStringYyyyMmDd = computed(() => {
	if (campaignStore.campaignFilter.filterCampaign.startDate) {
		const oneDayAfterStartDate = new Date(campaignStore.campaignFilter.filterCampaign.startDate + " 00:00:00");
		oneDayAfterStartDate.setDate(oneDayAfterStartDate.getDate() + 1);

		return convertDateToStringYyyyMmDd(oneDayAfterStartDate);
	
	} else {
		return null;
	}
});

const startDateDefaultYearMonth = computed(() => {
  const formatter = new Intl.DateTimeFormat('pt-BR', {
    year  : 'numeric',
    month : '2-digit',
    day   : '2-digit'
  });

	const now = new Date();
	now.setHours(0, 0, 0, 0);

  const dateStringYyyyMm = formatter.format(now).split('/').reverse().join('/').substring(0, 7);

  return dateStringYyyyMm;
});

const endDateDefaultYearMonth = computed(() => {
	if (endDateMinDateAllowedStringYyyyMmDd.value) {
		const dateStringYyyyMm = endDateMinDateAllowedStringYyyyMmDd.value.replaceAll('-', '/').substring(0, 7);

		return dateStringYyyyMm;

	} else {
		return startDateDefaultYearMonth.value;
	}
});

const endDateOptions = (dateStringYyyyMmDd: string) => {
	if (endDateMinDateAllowedStringYyyyMmDd.value) {
		return (dateStringYyyyMmDd >= endDateMinDateAllowedStringYyyyMmDd.value.replaceAll('-', '/'));

	} else {
		return true;
	}
};
</script>

<style scoped>

</style>