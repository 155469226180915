<template>
	<c-q-btn
		v-if="authStore.currentUserCanViewCampaign"
		id="btn-view-campaign"
		propBtnLabel="Visualizar"
		:propBtnFunction="onViewCampaignBtnClick"
		:propBtnTransparent="true"
		:propBtnSize="props.propBtnSize"
		:disable="disableViewSelectedCampaignsBtn"
	/>
</template>

<script setup lang="ts">
import { EnumButtonSizes, EnumRouteNames }	from '@/enums/enums';
import { useRouter }												from 'vue-router';
import { useCampaignStore }													from '@/stores/campaignStore';
import { useAuthStore }															from '@/stores/authStore';
import { computed }													from 'vue';

const router = useRouter();
const authStore		= useAuthStore();
const campaignStore   = useCampaignStore();

const props = defineProps({
  propBtnSize: {
		type			: String as () => keyof typeof EnumButtonSizes,
		required	: false,
		default		: EnumButtonSizes.LARGE_1
	}
});

const onViewCampaignBtnClick = () => {
	router.push({ name: EnumRouteNames.CAMPAIGN_VIEW });
};

const disableViewSelectedCampaignsBtn = computed(() => {
	try {
		return campaignStore.campaignCanBeViewed(campaignStore.focusedCampaign);
		
	} catch (error) {
		return true;
	}
})
</script>

<style scoped>

</style>
