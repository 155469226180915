<template>
    <c-q-btn
      id="back-button"
      propBtnLabel="Voltar"
      :propBtnFunction="backToPreviousPage"
      :propBtnTransparent="true"
      :propBtnSize="EnumButtonSizes.LARGE_1"
    >
    </c-q-btn>
  </template>
  
  <script setup lang="ts">
  import { EnumButtonSizes }	from '@/enums/enums';
  import { useRouter }        from 'vue-router';
  
  const router = useRouter();
  
  const backToPreviousPage = () => {
              router.back();
  };
  </script>
  
  <style scoped>
  
  </style>