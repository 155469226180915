<template>
	<c-q-btn
		v-if="authStore.currentUserCanReviewCampaign"
		id="btn-review-campaign"
		propBtnLabel="Revisar Campanha"
		:propBtnFunction="onReviewCampaignBtnClick"
		:propBtnTransparent="false"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>
</template>

<script setup lang="ts">
import { EnumButtonSizes, EnumRouteNames }	from '@/enums/enums';
import { useRouter }												from 'vue-router';
import { useCampaignStore }									from '@/stores/campaignStore';
import { useAuthStore }					from '@/stores/authStore'

const router = useRouter();

const campaignStore = useCampaignStore();
const authStore 			= useAuthStore();

const onReviewCampaignBtnClick = () => {
	if (campaignStore.validateFocusedCampaignFields()) {
		router.push({ name: EnumRouteNames.CAMPAIGN_REVIEW });
	}
};
</script>

<style scoped>

</style>
