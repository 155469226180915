<template>
	<c-q-btn
		id="btn-delete-selected-campaigns"
		propBtnLabel="Excluir"
		:propBtnFunction="onDeleteSelectedCampaignsBtnClick"
		:propBtnTransparent="true"
		:propBtnSize="EnumButtonSizes.LARGE_1"
		:disable="disableDeleteSelectedCampaignsBtn"
	/>
</template>

<script setup lang="ts">
import { EnumMultipleSingleMode, EnumButtonSizes }	from '@/enums/enums';
import { useCampaignStore }													from '@/stores/campaignStore';
import { usePagesUtilStore }												from '@/stores/pagesUtilStore';
import { useAuthStore }															from '@/stores/authStore';
import { computed }																	from 'vue';

const campaignStore   = useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();
const authStore				= useAuthStore();

const onDeleteSelectedCampaignsBtnClick = () => {
  pagesUtilStore.modalDeleteCampaignConfirmationMode = EnumMultipleSingleMode.MULTIPLE;
  pagesUtilStore.showModalDeleteCampaignConfirmation = true;
};

const disableDeleteSelectedCampaignsBtn = computed(() => {
	try {
		return (
			(campaignStore.selectedCampaigns.length == 0)
			|| !authStore.currentUserCanDeleteCampaign
		);
		
	} catch (error) {
		return true;
	}
});
</script>

<style scoped>

</style>
