import "quasar/dist/quasar.css";
import "@quasar/extras/material-icons/material-icons.css";
import ptBR       from "quasar/lang/pt-BR";
import Notify from 'quasar/src/plugins/notify/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config  : {},
  plugins : { Notify },
  lang    : ptBR
};
