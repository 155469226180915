import { campaignFormatATMScreenFilterInterface } from '@/types/types.d';

export class CampaignFormatATMScreenFilterModel implements campaignFormatATMScreenFilterInterface {
	screen				: string | undefined;
	pcs						: string | undefined;
	institutions	: string | undefined;

	constructor(
		newFormatATMScreenFilter: {
			screen?				: string,
			pcs?					: string,
			institutions?	: string,
		} ) {
		this.screen				= newFormatATMScreenFilter.screen;
		this.pcs					= newFormatATMScreenFilter.pcs;
		this.institutions	= newFormatATMScreenFilter.institutions;
	}
}
