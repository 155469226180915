import { defineStore }                                                            from 'pinia';
import { Ref, ref }                                                               from 'vue';
import { campaignInterface, campaignFilterInterface, campaignJsonSlotInterface }  from '@/types/types.d';
import { CampaignModel }                                                          from '@/models/CampaignModel';
import { CampaignFilterModel }                                                    from '@/models/CampaignFilterModel';
import ApiService                                                                 from '@/utils/ApiService';
import { useFinancialInstitutionStore }                                           from '@/stores/financialInstitutionStore';
import { usePCStore }                                                             from '@/stores/pcStore';
import { useAuthStore }															                              from '@/stores/authStore';
import { formatDateStringDdMmYyyyAsYyyyMmDd, convertDateToStringYyyyMmDd }        from '@/utils/commonUtils';
import { CONST_CAMPAIGN_STATUS }                                                  from '@/static/constants';
import { EnumCampaignFormats }                                                    from '@/enums/enums';
import messages                                                                   from '@/utils/messages';
import axios from 'axios';
export const useCampaignStore = defineStore('campaign', () => {
  // state
  const campaigns                     : Ref<campaignInterface[]>      = ref([] as campaignInterface[]);
  const selectedCampaigns             : Ref<campaignInterface[]>      = ref([] as campaignInterface[]);
  const focusedCampaign               : Ref<campaignInterface>        = ref(new CampaignModel({}));
  const focusedCampaignSnapshot       : Ref<campaignInterface>        = ref(new CampaignModel({}));
  const focusedCampaignImageObjectUrl : Ref<string>                   = ref("");
  const campaignFilter                : Ref<campaignFilterInterface>  = ref(new CampaignFilterModel());
  const selectedOption                : Ref<string>                   = ref("");
  const refRequestedAdjustments       : Ref<string>                   = ref("");

  const formatAtmScreenImageFileSlot  : Ref<File | undefined>         = ref(undefined);
  const campaignJsonSlot              : Ref<campaignJsonSlotInterface | undefined> = ref(undefined);
  // const editIsComingFromDraft         : Ref<boolean>                   = ref(false);

  const updateCampaignSnapshot = () => {
    focusedCampaignSnapshot.value = Object.freeze(new CampaignModel(focusedCampaign.value));
  };

  const setSelectedOption = (option?:any) =>{
    selectedOption.value = option;
  }

  const setFocusedCampaign = (newCampaignToSet: campaignInterface) => {
    focusedCampaign.value = new CampaignModel(newCampaignToSet);

    updateCampaignSnapshot();
  };

  const fetchCampaigns = async () => {
    try {
      const params = {
        advertiser  : (campaignFilter.value.filterCampaign.advertiser         ? campaignFilter.value.filterCampaign.advertiser                                            : undefined),
        agency      : (campaignFilter.value.filterCampaign.agency             ? campaignFilter.value.filterCampaign.agency                                                : undefined),
        name        : (campaignFilter.value.filterCampaign.name               ? campaignFilter.value.filterCampaign.name                                                  : undefined),
        start_date  : (campaignFilter.value.filterCampaign.startDateDdMmYyyy  ? formatDateStringDdMmYyyyAsYyyyMmDd(campaignFilter.value.filterCampaign.startDateDdMmYyyy) : undefined),
        end_date    : (campaignFilter.value.filterCampaign.endDateDdMmYyyy    ? formatDateStringDdMmYyyyAsYyyyMmDd(campaignFilter.value.filterCampaign.endDateDdMmYyyy)   : undefined),
        format      : (campaignFilter.value.filterCampaign.format             ? campaignFilter.value.filterCampaign.format                                                : undefined),
        status      : (campaignFilter.value.filterCampaign.status             ? campaignFilter.value.filterCampaign.status                                                : undefined),
        pcsIds      : (campaignFilter.value.filterCampaignFormatATMScreen.pcs ? campaignFilter.value.filterCampaignFormatATMScreen.pcs                                    : undefined),
        ifsIds      : (campaignFilter.value.filterCampaignFormatATMScreen.institutions             ? campaignFilter.value.filterCampaignFormatATMScreen.institutions      : undefined),
        slot        : (campaignFilter.value.filterCampaignFormatATMScreen.screen ? campaignFilter.value.filterCampaignFormatATMScreen.screen : undefined) // TODO: verificar!
      };

      const responseData = await ApiService.get<[CampaignModel]>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_GET_CAMPAIGN,
        params
      );
      
      campaigns.value.length = 0;
      campaigns.value.push( ...responseData.map(data => new CampaignModel(data)) );
      
      campaigns.value.sort((c1, c2) => {
        if (
          c1.startDate
          && c2.startDate
        ) {
          if (c2.startDate > c1.startDate) {
            return 1;
          
          } else {
            return -1;
          }

        } else {
          return 0;
        }
      });
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const deleteCampaigns = async (params: object) => {
    try {
      await ApiService.put<undefined>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_DELETE_CAMPAIGN,
        "",
        params
      );
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const deleteSelectedCampaigns = async () => {
    try {
      const params = {
        ids: selectedCampaigns.value.map(c => c.id).toString()
      };

      await deleteCampaigns(params);
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const deleteFocusedCampaign = async () => {
    try {
      const params = {
        ids: focusedCampaign.value.id
      };

      await deleteCampaigns(params);
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const saveFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.post<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_POST_CAMPAIGN_SAVE,
        focusedCampaign.value
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Já existe uma campanha cadastrada com o mesmo PC, IF ou vigência.");
      }
      console.error(error);
      throw new Error("Erro ao tentar salvar a campanha...");
    }
  };
  
  const sendToApproveFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.post<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_POST_CAMPAIGN_SEND_TO_APPROVE,
        focusedCampaign.value
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Já existe uma campanha cadastrada com o mesmo PC, IF ou vigência.");
      }
      console.error(error);
      throw new Error("Erro ao tentar enviar a campanha para aprovação...");
      // throw error;
    }
  };

  const resendToApproveFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_POST_CAMPAIGN_RESEND_TO_APPROVE,
        focusedCampaign.value,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Já existe uma campanha cadastrada com o mesmo PC, IF ou vigência.");
      }
      console.error(error);
      throw new Error("Erro ao tentar reenviar a campanha para aprovação...");
    }
  };
  
  const approveFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_APPROVE,
        null,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Erro ao aprovar a campanha. Só é possível aprovar campanhas que estejam com o status EM APROVAÇÃO.");
      }
      console.error(error);
      throw new Error("Erro ao tentar aprovar a campanha...");
      // throw error;
    }
  };
  
  const cancelFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_CANCEL,
        null,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };
  
  const editFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_EDIT,
        null,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };
  
  const sendRequestedAdjustmentsFocusedCampaign = async (requestedAdjustments: string) => {
    try {
      const focusedCampaignWithRequestAdjusmentsText = new CampaignModel(focusedCampaign.value);
      focusedCampaignWithRequestAdjusmentsText.commentadjustment = requestedAdjustments;

      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_REQUEST_ADJUSTMENTS,
        focusedCampaignWithRequestAdjusmentsText,
        { id: focusedCampaign.value.id }
      );
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error:any) {
      if(error.response.status == 400){
        throw new Error("Erro ao solicitar ajuste para a campanha. Só é possível solicitar ajustes para campanhas que estejam com o status EM APROVAÇÃO.");
      }
      console.error(error);
      throw new Error("Erro ao tentar solicitar ajustes...");
    }
  };

  const sendImageToS3 = async (imageFile: File | null) => {
    try {
      if (imageFile) {
        const formData = new FormData();
        formData.append('image', imageFile);

        const additionalHeaders = {
          'Content-Type': 'multipart/form-data'
        }
  
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = await ApiService.post<any>(
          process.env.VUE_APP_API_GATEWAY_HOST,
          process.env.VUE_APP_FILE_UPLOAD_S3_URL,
          formData,
          additionalHeaders
        );

        return response;
      }
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const saveFormatAtmImageSlotOnS3 = async () => {
    if (formatAtmScreenImageFileSlot.value) {
      try {
        const urlImageS3 = await sendImageToS3(formatAtmScreenImageFileSlot.value);

        if(focusedCampaign.value.slot1 != null){
          const slot1Json = convertCampaignStringSlotToCampaignJsonSlotInterface(focusedCampaign.value.slot1)
          slot1Json.imageObjectUrl = urlImageS3.uploadLocation;
          focusedCampaign.value.slot1 = convertCampaignJsonSlotInterfaceToCampaignStringSlot(slot1Json);
        
        }else if(focusedCampaign.value.slot2 != null){
          const slot2Json = convertCampaignStringSlotToCampaignJsonSlotInterface(focusedCampaign.value.slot2)
          slot2Json.imageObjectUrl = urlImageS3.uploadLocation;
          focusedCampaign.value.slot2 = convertCampaignJsonSlotInterfaceToCampaignStringSlot(slot2Json);
        
        }else if(focusedCampaign.value.slot3 != null){
          const slot3Json = convertCampaignStringSlotToCampaignJsonSlotInterface(focusedCampaign.value.slot3)
          slot3Json.imageObjectUrl = urlImageS3.uploadLocation;
          focusedCampaign.value.slot3 = convertCampaignJsonSlotInterfaceToCampaignStringSlot(slot3Json);
        }

        formatAtmScreenImageFileSlot.value = undefined;
        
      } catch (error) {
        console.error(error);
        // throw new Error("Erro ao salvar a imagem.");
        // messages.showErrorMsg("Erro ao tentar salvar a imagem...");
        throw error;
      }
    }
  };

  const campaignIsInEffect = (c: campaignInterface) => {
    try {      
      if (c.startDate && c.endDate) {
        const nowAsStringYyyyMmDd = convertDateToStringYyyyMmDd(new Date());

        return (
          (nowAsStringYyyyMmDd >= c.startDate)
          && (nowAsStringYyyyMmDd <= c.endDate)
        );

      } else {
        return false;
      }

    } catch (error) {
      return false;
    }
  };

  const campaignCanBeViewed = (c: campaignInterface) => {
    const authStore = useAuthStore();
    return (
      authStore.currentUserCanViewCampaign
      && (
        (c?.status == CONST_CAMPAIGN_STATUS.CANCELED.id)
      )
    );
  };

  const vigenciaJaTerminou = (c: campaignInterface) => {
    try {      
      if (c.startDate && c.endDate) {
        const nowAsStringYyyyMmDd = convertDateToStringYyyyMmDd(new Date());
 
        return (
          (nowAsStringYyyyMmDd > c.endDate)
        );
 
      } else {
        return false;
      }
 
    } catch (error) {
      return false;
    }
  };
 
  const campaignCanBeDeleted = (c: campaignInterface) => {
    const authStore = useAuthStore();
 
    return (
      authStore.currentUserCanDeleteCampaign
      && (
        (
          (c?.status === CONST_CAMPAIGN_STATUS.CANCELED.id)
          || (c?.status === CONST_CAMPAIGN_STATUS.DRAFT.id)
        )
        || (
          (c?.status === CONST_CAMPAIGN_STATUS.PUBLISHED.id)
          && vigenciaJaTerminou(c)
        )
      )
    );
  };

  const campaignCanBeEdited = (c: campaignInterface) => {
    const authStore = useAuthStore();

    return (
      !authStore.currentUserCanEditCampaign
      && (
        !(c?.status == CONST_CAMPAIGN_STATUS.APPROVED.id)
        || !(c?.status == CONST_CAMPAIGN_STATUS.PUBLISHED.id)
        || !(c?.status == CONST_CAMPAIGN_STATUS.ADJUSTMENT_REQUESTED.id)
        || !(c?.status == CONST_CAMPAIGN_STATUS.DRAFT.id)
        || !(c?.status == CONST_CAMPAIGN_STATUS.ON_EDITING.id)
      )
    );
  }

  const allSelectedCampaignsCanBeDeleted = () => {
    try {
      let result = true;

      if (selectedCampaigns.value.length > 0) {
        selectedCampaigns.value.forEach(c => {
          if (!campaignCanBeDeleted(c)) {
            messages.showWarningMsg(`Campanha ${c.name} não pode ser excluída...`);

            result = false;
          }
        });
      }
  
      return result;
        
    } catch (error) {
      return false;
    }
  };

  const publishFocusedCampaign = async () => {
    try {
      const responseData = await ApiService.put<CampaignModel>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_PUT_CAMPAIGN_PUBLISH,
        null,
        { id: focusedCampaign.value.id }
      );
      
      try{
        await generateAndSendEmailPackageCampaign();
      }catch(err){
        console.error(err);
      }
      
      setFocusedCampaign(new CampaignModel(responseData));
      
    } catch (error) {
      console.error(error);
      messages.showErrorMsg(`Não foi possivel publicar a Campanha...`);

      throw error;
    }
  }

  const fetchImage = async (imageURL: string) =>{
    try {
      const response = await fetch(imageURL);
      
      // Verifica se a resposta foi bem sucedida
      if (!response.ok) {
        throw new Error(`Erro ao buscar a imagem: ${response.statusText}`);
      }
      
      // Converte a resposta em um blob
      const blob = await response.blob();
      
      return blob;
    } catch (error) {
      console.error("Erro ao buscar a imagem:", error);
      throw error;
    }
  }

  const generateAndSendEmailPackageCampaign = async () => {
    const s3ImageUrlInfo = focusedCampaign.value.formatAtmScreenImageSlotS3Url();
    
    if (s3ImageUrlInfo) {
      const { imageUrl, fileName } = s3ImageUrlInfo;
  
      try {
        // Busca a imagem e converte para blob
        const fileBlob = await fetchImage(imageUrl);
  
        const slotNames = ["slot3-1.0.png", "slot1-1.0.png", "slot2-1.0.png"];
        const slotIndex = focusedCampaign.value.slot1 ? 1 : (focusedCampaign.value.slot2 ? 2 : 0);
        const slotName = slotNames[slotIndex];
        
        const formData = new FormData();
        formData.append('nomeArquivo', process.env.VUE_APP_NAME_FILE);
        formData.append('ID', String(focusedCampaign.value.id));
        formData.append('NOME_CAMPANHA', String(focusedCampaign.value.name));
        formData.append('COD_PCS', String(focusedCampaign.value.pcsIds));
        formData.append('COD_INTERBANK', String(focusedCampaign.value.ifsIds));
        formData.append('SLOT_IMAGE_NAME', String(slotName));
        formData.append('DATA_INI', String(formatDate(focusedCampaign.value.startDateDdMmYyyy)));
        formData.append('DATA_FIM', String(formatDate(focusedCampaign.value.endDateDdMmYyyy)));
        formData.append('IMG1', fileBlob, fileName);
        
        const responseData = await ApiService.post(
          process.env.VUE_APP_API_PACKAGE_ENDPOINT,
          process.env.VUE_APP_API_PACKAGE_GENERATE,
          formData
        );
      
        return responseData;
      } catch (error) {
        console.error('Erro ao processar os dados do formulário:', error);
        throw error;
      }
    }
  }

  const resetState = () => {
    campaigns.value.length         = 0;
    selectedCampaigns.value.length = 0;
    focusedCampaign.value          = new CampaignModel({});
    focusedCampaignSnapshot.value  = new CampaignModel({});
  }

  const resetCampaignFilter = () => {
    campaignFilter.value = new CampaignFilterModel();
  };

  const  validateFocusedCampaignFormatAtmScreens = () => {
    try {
      const messagesArray = [];

      if (focusedCampaign.value.ifs.length == 0) {
        messagesArray.push("Favor informar pelo menos uma instituição.");
      }
  
      if (focusedCampaign.value.pcs.length == 0) {
        messagesArray.push("Favor informar pelo menos um PC.");
      }

      if (
        !focusedCampaign.value.slot1
        && !focusedCampaign.value.slot2
        && !focusedCampaign.value.slot3
      ) {
        messagesArray.push("Favor escolher e importar pelo menos uma imagem.");
      }

      messagesArray.reverse().forEach(m => {
        messages.showWarningMsg(m);
      });

      return (messagesArray.length == 0);
        
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const validateFocusedCampaignFields = () => {
    try {
      let validateResult = true;

      const messagesArray = [];

      if (!focusedCampaign.value.name) {
        messagesArray.push("Favor preencher o Nome da Campanha");
      }
  
      if (!focusedCampaign.value.startDate) {
        messagesArray.push("Favor informar a Data de Início da Vigência desta campanha");
      
      } else if (focusedCampaign.value.startDate <= convertDateToStringYyyyMmDd(new Date())) {
        messagesArray.push("Data de início de vigência deve ser maior do que a data atual (hoje)");
      }

      if (!focusedCampaign.value.endDate) {
        messagesArray.push("Favor informar a Data de Fim da Vigência desta campanha");
      }

      if (
        focusedCampaign.value.startDate
        && focusedCampaign.value.endDate
      ) {

        if (focusedCampaign.value.endDate <= focusedCampaign.value.startDate) {
          messagesArray.push("Data de fim da vigência deve ser maior que a data de Início da vigência");
        }
      }
  
      if (!focusedCampaign.value.advertiser) {
        messagesArray.push("Favor informar o Anunciante da campanha");
      }

      if (!focusedCampaign.value.format) {
        messagesArray.push("Favor selecionar um formato");
      
      } else if (focusedCampaign.value.format == EnumCampaignFormats.ATM_SCREEN) {
        if (!validateFocusedCampaignFormatAtmScreens()) {
          validateResult = false;
        }
      }

      if (messagesArray.length > 0) {
        validateResult = false;

        messagesArray.reverse().forEach(m => {
          messages.showWarningMsg(m);
        });
      }

      return validateResult;
        
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const convertCampaignStringSlotToCampaignJsonSlotInterface = (stringSlot: string | undefined) => {
    try {
      if (stringSlot) {
        const parsedStringSlot: campaignJsonSlotInterface = JSON.parse(stringSlot);
        return parsedStringSlot;
      
      } else {
        return {
          imageObjectUrl  : "",
          imageS3Url      : undefined,
          imageFileName   : "",
          imageWidth		  : undefined,
          imageHeight		  : undefined,
          imageSize			  : undefined
        };
      }
      
    } catch (error) {
      return {
        imageObjectUrl  : "",
        imageS3Url      : undefined,
        imageFileName   : "",
        imageWidth		  : undefined,
        imageHeight		  : undefined,
        imageSize			  : undefined
      };
    }
  };

  const convertCampaignJsonSlotInterfaceToCampaignStringSlot = (jsonSlot: campaignJsonSlotInterface | undefined) => {
    try {
      if (jsonSlot) {
        const stringifiedJsonSlot: string = JSON.stringify(jsonSlot);
  
        return stringifiedJsonSlot;

      } else {
        return undefined;  
      }
      
    } catch (error) {
      return undefined;
    }
  };

  const exportFocusedCampaignIfsAsCsv = (paramFilename?: string) => {
    const financialInstitutionStore = useFinancialInstitutionStore();
    
    financialInstitutionStore.exportIfsAsCsv(focusedCampaign.value.ifs, paramFilename);
  };

  const exportFocusedCampaignPcsAsCsv = (paramFilename?: string) => {
    const pcStore = usePCStore();

    pcStore.exportPcsAsCsv(focusedCampaign.value.pcs, paramFilename);
  };

  function formatDate(inputDate?: string) {

    if (!inputDate) {
      return ''; // Or handle the case in your application logic
    }
    // Split the input date string into year, month, and day
    const [day, month, year ] = inputDate.split('/');
    
    // Format the date as MMDDYYYY
    const formattedDate = `${day}${month}${year}`;
    
    return formattedDate;
  }

  // getters

  return {
    // state
    campaigns,
    selectedCampaigns,
    focusedCampaign,
    focusedCampaignSnapshot,
    focusedCampaignImageObjectUrl,
    campaignFilter,
    formatAtmScreenImageFileSlot,
    selectedOption,
    refRequestedAdjustments,
    campaignJsonSlot,
    // editIsComingFromDraft,
    /* advertiserOptions, */

    // actions
    updateCampaignSnapshot,
    setFocusedCampaign,
    fetchCampaigns,
    deleteSelectedCampaigns,
    deleteFocusedCampaign,
    saveFocusedCampaign,
    sendToApproveFocusedCampaign,
    resendToApproveFocusedCampaign,
    approveFocusedCampaign,
    cancelFocusedCampaign,
    editFocusedCampaign,
    sendRequestedAdjustmentsFocusedCampaign,
    saveFormatAtmImageSlotOnS3,
    setSelectedOption,

    campaignCanBeDeleted,
    allSelectedCampaignsCanBeDeleted,
    campaignCanBeEdited,
    campaignCanBeViewed,
    exportFocusedCampaignIfsAsCsv,
    exportFocusedCampaignPcsAsCsv,
    publishFocusedCampaign,
    generateAndSendEmailPackageCampaign,
    
    resetState,
    resetCampaignFilter,

    validateFocusedCampaignFields,

    convertCampaignStringSlotToCampaignJsonSlotInterface,
    convertCampaignJsonSlotInterfaceToCampaignStringSlot,

    // getters
  }
})
