<template>
  <div>
    <c-q-separator class="q-pb-lg" :withBackButton="true" />

    <PageCaption
      pageCaptionTitle="Nova Campanha"
      pageCaptionDescription="Envie uma nova Campanha para Aprovação"
    />

    <CampaignIdentificationSectionEdit />

    <CampaignFormatSectionEdit />

    <c-q-separator class="q-pt-lg q-pb-lg" />

    <CampaignActionButtonsSection
      :propShowBtnCancelCreateNewCampaign="true"
      :propShowBtnReviewFocusedCampaign="true"
    />
  </div>
</template>

<script setup lang="ts">
import PageCaption	                      from '@/components/PageCaption.vue';
import CampaignIdentificationSectionEdit  from '@/components/campaign/CampaignIdentificationSectionEdit.vue';
import CampaignFormatSectionEdit          from '@/components/campaign/CampaignFormatSectionEdit.vue';
import CampaignActionButtonsSection       from '@/components/campaign/CampaignActionButtonsSection.vue';
import { onBeforeMount }                  from 'vue';
import { useCampaignStore }               from '@/stores/campaignStore';
import { CampaignModel }                  from '@/models/CampaignModel';

const campaignStore = useCampaignStore();

/* onBeforeMount(() => {
  campaignStore.focusedCampaign          = new CampaignModel({});
  campaignStore.focusedCampaignSnapshot  = new CampaignModel({});
}); */
</script>

<style scoped>

</style>