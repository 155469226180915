<template>
  <div>
    <ModalFilterCampaignCampaignFormatATMScreen v-if="showFormatATMScreen" />
  </div>
</template>

<script setup lang="ts">
import { useCampaignStore }     					        from '@/stores/campaignStore';
import ModalFilterCampaignCampaignFormatATMScreen from '@/components/campaign/campaignFormats/ModalFilterCampaignCampaignFormatATMScreen.vue';
import { computed }             					        from 'vue';
import { EnumCampaignFormats }  					        from '@/enums/enums';

const campaignStore = useCampaignStore();

const showFormatATMScreen = computed(() => {
  return (campaignStore.campaignFilter.filterCampaign.format == EnumCampaignFormats.ATM_SCREEN);
});
</script>

<style scoped>

</style>
