<template>
	<div>
		<div class="row col q-pb-sm">
			<q-select
				v-model="campaignStore.campaignFilter.filterCampaignFormatATMScreen.screen"
				:options="optionsScreen"
				outlined
				option-label="screenDescription"
				option-value="screenId"
				map-options
				emit-value
				dense
				color="globalAppPrimaryColor"
				style=" width: 100%; "
				:label-slot="!campaignStore.campaignFilter.filterCampaignFormatATMScreen.screen"
			>
				<template v-slot:label>
					<span class="midia-app-placeholder-lg">Tela</span>
				</template>

				<template v-slot:option="{ itemProps, opt }">
					<q-item v-bind="itemProps">
					<q-item-section>
					<q-item-label>{{ sanitizeHTML(opt.screenDescription) }}</q-item-label>
					</q-item-section>
					</q-item>
				</template>
			</q-select>
		</div>

		<div class="row col q-pb-sm">
			<q-input
				v-model="campaignStore.campaignFilter.filterCampaignFormatATMScreen.pcs"
				color="globalAppPrimaryColor"
				bg-color="globalAppDefaultBackgroundColor"
				outlined
				dense
				placeholder="PCs"
				input-class="midia-app-content-lg"
				style=" width: 100%; "
			/>
		</div>

		<div class="row col">
			<q-select
				v-model="campaignStore.campaignFilter.filterCampaignFormatATMScreen.institutions"
				:options="optionsInstitutions"
				option-label="nome"
				option-value="codif"
				emit-value
				map-options
				outlined
				dense
				color="globalAppPrimaryColor"
				style=" width: 100%; "
				:label-slot="!campaignStore.campaignFilter.filterCampaignFormatATMScreen.institutions"
			>

			<template v-slot:label>
					<span class="midia-app-placeholder-md">Instituições</span>
			</template>

			<template v-slot:option="{ itemProps, opt }">
				<q-item v-bind="itemProps">
				<q-item-section>
				<q-item-label>{{ sanitizeHTML(opt.nome) }}</q-item-label>
				</q-item-section>
				</q-item>
			</template>
				<!-- <template v-slot:label>
					<span class="midia-app-placeholder-md">Instituições</span>
				</template>

				<template v-slot:selected>
					<span class="midia-app-content-lg">{{ campaignStore.campaignFilter.filterCampaignFormatATMScreen.institutions }}</span>
				</template> -->
			</q-select>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useCampaignStore }																	from '@/stores/campaignStore';
import { useFinancialInstitutionStore }											from '@/stores/financialInstitutionStore';
import { usePagesUtilStore }																from '@/stores/pagesUtilStore';
import { CONST_CAMPAIGN_ATM_SCREEN_FORMAT_SCREEN_OPTIONS }	from '@/static/constants';
import { computed, onBeforeMount }													from 'vue';

const campaignStore							= useCampaignStore();
const financialInstitutionStore	= useFinancialInstitutionStore();
const pagesUtilStore						= usePagesUtilStore();

const optionsScreen				= computed(() => {
	return CONST_CAMPAIGN_ATM_SCREEN_FORMAT_SCREEN_OPTIONS;
});

const optionsInstitutions	= computed(() => {
	return financialInstitutionStore.financialInstitutions;
});

const sanitizeHTML = (html:any) => {
      return html; // For demonstration purpose only, please sanitize the HTML properly
    }



onBeforeMount(async () => {
  financialInstitutionStore.resetState();
  try {
    pagesUtilStore.showLoading = true;
  
    await financialInstitutionStore.fetchFinancialInstitutions();

  } finally {
    pagesUtilStore.showLoading = false;
  }      
});
</script>

<style scoped>

</style>