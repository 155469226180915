import { pcInterface } from '@/types/types.d';

export class PcModel implements pcInterface {
	codpontocaptura						: number | undefined;
	nompontocaptura						: string | undefined;
	// codstaptocaptura					: number | undefined;
	// nomstaptocaptura					: string | undefined;
	// codcidredeautoatndto			: number | undefined;
	ufredeautoatndto					: string | undefined;
	nomcidredeautoatndto			: string | undefined;
	// codsegmmerclocalptoatndto	: number | undefined;
	nomsegmmerclocalptoatndto	: string | undefined;
	// codsubredeautoatndto			: number | undefined;
	nomsubredeautoatndto			: string | undefined;
	// codtpmaqautoatndto				: number | undefined | null;
	nomtpmaqautoatndto				: string | undefined | null;
	// servicos									: string | undefined;

	constructor(
		newPC: {
			codpontocaptura?						: number,
			nompontocaptura?						: string,
			// codstaptocaptura?						: number,
			// nomstaptocaptura?						: string,
			// codcidredeautoatndto?				: number,
			ufredeautoatndto?						: string,
			nomcidredeautoatndto?				: string,
			// codsegmmerclocalptoatndto?	: number,
			nomsegmmerclocalptoatndto?	: string,
			// codsubredeautoatndto?				: number,
			nomsubredeautoatndto?				: string,
			// codtpmaqautoatndto?					: number | null,
			nomtpmaqautoatndto?					: string | null,
			// servicos?										: string
		} ) {
		this.codpontocaptura						= newPC.codpontocaptura;
		this.nompontocaptura						= newPC.nompontocaptura;
		// this.codstaptocaptura						= newPC.codstaptocaptura;
		// this.nomstaptocaptura						= newPC.nomstaptocaptura;
		// this.codcidredeautoatndto				= newPC.codcidredeautoatndto;
		this.ufredeautoatndto						= newPC.ufredeautoatndto;
		this.nomcidredeautoatndto				= newPC.nomcidredeautoatndto;
		// this.codsegmmerclocalptoatndto	= newPC.codsegmmerclocalptoatndto;
		this.nomsegmmerclocalptoatndto	= newPC.nomsegmmerclocalptoatndto;
		// this.codsubredeautoatndto				= newPC.codsubredeautoatndto;
		this.nomsubredeautoatndto				= newPC.nomsubredeautoatndto;
		// this.codtpmaqautoatndto					= newPC.codtpmaqautoatndto;
		this.nomtpmaqautoatndto					= newPC.nomtpmaqautoatndto;
		// this.servicos										= newPC.servicos;
	}
}
