import script from "./LayoutContent.vue?vue&type=script&setup=true&lang=ts"
export * from "./LayoutContent.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInnerLoading,QSpinner});
