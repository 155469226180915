<template>
  <div>
    <CampaignFormatATMScreenEdit v-if="showFormatATMScreen" />
  </div>
</template>

<script setup lang="ts">
import { useCampaignStore }         from '@/stores/campaignStore';
import CampaignFormatATMScreenEdit  from '@/components/campaign/campaignFormats/CampaignFormatATMScreenEdit.vue';
import { computed }                 from 'vue';
import { EnumCampaignFormats }      from '@/enums/enums';

const campaignStore = useCampaignStore();

const showFormatATMScreen = computed(() => {
  return (campaignStore.focusedCampaign.format == EnumCampaignFormats.ATM_SCREEN);
});
</script>

<style scoped>

</style>
