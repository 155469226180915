<template>
  <div id="div-layout-footer" style=" height: 100%; ">
    <c-q-separator class="q-mx-xl" />

    <div id="div-content-footer" class="flex items-center justify-center q-pb-md" style=" height: 100%; ">
      <div class="row items-center justify-center" style=" width: 100%; ">
        <div class="col-10 justify-start q-px-lg">
          <div>
            <div id="div-logo-footer" class="row q-pl-xl">
              <img src="@/assets/logos/logo_TecBan_MidiaFlow.svg" width="200">
            </div>
          
            <q-separator />
          </div>

          <div id="div-textos-footer" class="row q-pt-md">
            <span class="midia-app-label-footer-xs">Política de Privacidade de Dados | Termos de Uso e Cookies</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>
