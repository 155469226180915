<template>
  <router-view id="router-view-root-routes" />

  <q-inner-loading :showing="pagesUtilStore.showLoading">
    <q-spinner size="12em" />
  </q-inner-loading>
</template>

<script setup lang="ts">
import { usePagesUtilStore }  from '@/stores/pagesUtilStore';

const pagesUtilStore = usePagesUtilStore();
</script>

<style scoped>

</style>