export enum EnumMultipleSingleMode {
  MULTIPLE	= 0,
  SINGLE		= 1
};

export enum EnumCampaignStatus {
  ON_EDITING            = 'Em Edição',
  DRAFT                 = 'Rascunho',
  ON_APPROVAL           = 'Em Aprovação',
  APPROVED              = 'Aprovada',
  ADJUSTMENT_REQUESTED  = 'Solicitado Ajuste',
  PUBLISHED             = 'Publicada',
  CANCELED              = 'Cancelada'
}

export enum EnumCampaignFormats {
  ATM_SCREEN = 'ATM Screen'
}

export enum EnumATMScreenFormatSlotTypes {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3'
}

export enum EnumRequestedAdjustmentsModalType {
  CREATE  = 0,
  UPDATE  = 1
}

export enum EnumRouteNames {
  ROOT            = 'root',
  HOME            = 'home',
  CAMPAIGN_HOME   = 'campaign',
  CAMPAIGN_SEARCH = 'searchCampaign',
  CAMPAIGN_NEW    = 'newCampaign',
  CAMPAIGN_EDIT   = 'editCampaign',
  CAMPAIGN_VIEW   = 'viewCampaign',
  CAMPAIGN_REVIEW = 'reviewCampaign',
  MY_PROFILE      = 'myProfile'
}

export enum EnumButtonSizes {
  SMALL   = '8px',
  MEDIUM  = '14px',
	LARGE_1	= '16px',
	LARGE_2	= '18px'
}
