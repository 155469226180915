<template>
<!-- <q-dialog
		v-model="pagesUtilStore.showModalConfirmationDialog"
		:no-backdrop-dismiss="true"
	> --> 

	<!--NOVO-->
	<q-dialog
		v-model="dialogVisible"
		:no-backdrop-dismiss="true"
	>
    <q-card class="midia-app-padding-modal">
      <q-card-section>
				<c-modal-title
					:propModalTitle="props.propDialogTitle"
					:propModalDescription="props.propConfirmationMsg"
				/>
      </q-card-section>

			<q-card-actions align="right">
				<div class="q-pr-sm">
					<c-q-btn
						id="btn-cancel"
						:propBtnLabel="props.propCancelBtnLabel"
						:propBtnTransparent="true"
						:propBtnSize="EnumButtonSizes.LARGE_1"
						@click="handleCancel"
						
					/>
				</div>

				<div class="q-pl-sm">
					<c-q-btn
						id="btn-confirm"
						:propBtnLabel="props.propConfirmBtnLabel"
						:propBtnTransparent="false"
						:propBtnSize="EnumButtonSizes.LARGE_1"
						@click="handleConfirm"
					/>
				</div>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { EnumButtonSizes }		from '@/enums/enums';
//NOVO
import { computed }							from 'vue';

const pagesUtilStore = usePagesUtilStore();

const props = defineProps({
	propDialogTitle			: {
		type			: String,
		required	: false,
		default		: "Confirmação"
	},

	propConfirmationMsg	: String,

	propCancelBtnLabel 	: {
		type			: String,
		required	: false,
		default		: "Não"
	},

	propConfirmBtnLabel	: {
		type			: String,
		required	: false,
		default		: "Sim"
	},

	propDialogType: String
})

const emit = defineEmits(['confirm']);

/*const handleConfirm = () => {
  pagesUtilStore.showModalConfirmationDialog = false
  
  emit('confirm');
} */


//NOVO
const dialogVisible = computed(() => {
  if (props.propDialogType === 'institutions') {
    return pagesUtilStore.showModalConfirmationDialogIfs;
  } else if (props.propDialogType === 'pcs') {
    return pagesUtilStore.showModalConfirmationDialogPcs;
  } 
   else if(props.propDialogType === 'editar') {
	return pagesUtilStore.showModalConfirmationEditCampaign;
   }
	return false;
});

const handleCancel = () => {
  if (props.propDialogType === 'institutions') {
    pagesUtilStore.showModalConfirmationDialogIfs = false;
  } else if (props.propDialogType === 'pcs') {
    pagesUtilStore.showModalConfirmationDialogPcs = false;
  } 
   else if(props.propDialogType === 'editar') {
    pagesUtilStore.showModalConfirmationEditCampaign = false;
   }  
}

const handleConfirm = () => {
  if (props.propDialogType === 'institutions') {
    pagesUtilStore.showModalConfirmationDialogIfs = false;
  } else if (props.propDialogType === 'pcs') {
    pagesUtilStore.showModalConfirmationDialogPcs = false;
  } 
   else if(props.propDialogType === 'editar') {
	pagesUtilStore.showModalConfirmationEditCampaign = false;
   }  
  emit('confirm');
}

</script>
