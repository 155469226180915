import { defineStore }                    from 'pinia';
import { Ref, ref, computed}                       from 'vue';
import { financialInstitutionInterface }  from '@/types/types.d';
import { FinancialInstitutionModel }      from '@/models/FinancialInstitutionModel';
import ApiService                         from '@/utils/ApiService';
import { exportAsCsvFile }                from '@/utils/commonUtils';
import { DEFAULT_IFS_EXPORT_FILENAME }    from '@/static/constants';

export const useFinancialInstitutionStore = defineStore('financialInstitution', () => {
  // state
  const financialInstitutions         : Ref<financialInstitutionInterface[]> = ref([] as financialInstitutionInterface[]);
  const selectedFinancialInstitutions : Ref<financialInstitutionInterface[]> = ref([] as financialInstitutionInterface[]);
  const focusedFinancialInstitution   : Ref<financialInstitutionInterface>   = ref(new FinancialInstitutionModel({}));

  const pagination = ref({
    sortBy: 'codif',  
    descending: false,
    page: 2,   
    rowsPerPage: 5
  })

  // actions
  const fetchFinancialInstitutions = async (filterTerms?: string) => {
    try {
      const params = {
        filtroIfs: (filterTerms ? filterTerms : "")
      };

      const responseData = await ApiService.get<[FinancialInstitutionModel]>(
        process.env.VUE_APP_API_CAMPAIGN_ENDPOINT,
        process.env.VUE_APP_API_CAMPAIGN_ROUTE_GET_IFS,
        params
      );
        
      //const responseData = staticIfs;
      
      financialInstitutions.value.length = 0;
      financialInstitutions.value.push( ...responseData.map(data => new FinancialInstitutionModel(data)) );
      
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const resetState = () => {
    financialInstitutions.value.length          = 0;
    selectedFinancialInstitutions.value.length  = 0;
    focusedFinancialInstitution.value           = new FinancialInstitutionModel({});
  }

  const exportIfsAsCsv = (paramIfsToExport: Array<financialInstitutionInterface>, paramFilename: string = DEFAULT_IFS_EXPORT_FILENAME) => {
    if (paramIfsToExport) {
      const ifsToExport = paramIfsToExport.map(i => ({
        //"Código"      : i.codif,
        //"Código"      : `'${i.codif}`,
        "Código"      : `="${i.codif}"`,
        "Instituição" : i.nome,
        "Categoria"   : i.nomagrpacionista
      }));
  
      exportAsCsvFile(ifsToExport, paramFilename);
    }
  }

  // getters

  return {
    // state
    financialInstitutions,
    selectedFinancialInstitutions,
    focusedFinancialInstitution,
    pagination,
    pagesNumber: computed(() => Math.ceil(financialInstitutions.value.length / pagination.value.rowsPerPage)),

    // actions
    fetchFinancialInstitutions,
    resetState,
    exportIfsAsCsv

    // getters
  }
})
