import { financialInstitutionInterface } from '@/types/types.d';

export class FinancialInstitutionModel implements financialInstitutionInterface {
	codif							: string | undefined;
	nome							: string | undefined;
	codagrpacionista	: number | undefined | null;
	nomagrpacionista	: string | undefined | null;

	constructor(
		newPC: {
			codif?						: string,
			nome?							: string,
			codagrpacionista?	: number | null,
			nomagrpacionista?	: string | null
		} ) {
		this.codif						= newPC.codif;
		this.nome							= newPC.nome;
		this.codagrpacionista	= newPC.codagrpacionista;
		this.nomagrpacionista	= newPC.nomagrpacionista;
	}
}
