<template>
	<div>
		<div class="row q-pb-xs">
				<span class="midia-app-label-modal-sm">Formato</span>
		</div>

		<div class="row col">
			<q-select
				v-model="campaignStore.campaignFilter.filterCampaign.format"
				:options="formatOptions"
				outlined
				dense
				color="globalAppPrimaryColor"
				style=" width: 100%; "
				:label-slot="!campaignStore.campaignFilter.filterCampaign.format"
			>
				<template v-slot:label>
					<span class="midia-app-placeholder-md">Selecione o Formato</span>
				</template>

				<template v-slot:selected>
					<span class="midia-app-content-lg">{{ campaignStore.campaignFilter.filterCampaign.format }}</span>
				</template>
			</q-select>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useCampaignStore } 		from '@/stores/campaignStore';
import { onBeforeMount }				from 'vue';
import { EnumCampaignFormats }	from '@/enums/enums';

const campaignStore	= useCampaignStore();

const formatOptions	= Object.freeze([
	...Object.values(EnumCampaignFormats)
]);

/* onBeforeMount(() => {
	campaignStore.campaignFilterNew.format = "";
}); */
</script>

<style scoped>

</style>