<template>
  <div class="flex items-center justify-center" style=" min-height: inherit; background-color: var(--globalAppSecondaryColor4); ">

  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';

const router = useRouter();

router.beforeEach((to, from, next) => {
    sessionStorage.setItem('from', 'true'); // Mantém o histórico

    next(); // Continua a navegação normalmente
  });

</script>

<style scoped>

</style>