<template>
	<c-q-btn
		v-if="authStore.currentUserCanSaveCampaign 
		&& campaignStore.focusedCampaign.status == undefined"
		id="btn-save-campaign"
		propBtnLabel="Salvar"
		:propBtnFunction="onSaveCampaignBtnClick"
		:propBtnTransparent="true"
		:propBtnSize="EnumButtonSizes.LARGE_1"
	/>
</template>

<script setup lang="ts">
import { EnumButtonSizes }		from '@/enums/enums';
import { useCampaignStore }		from '@/stores/campaignStore';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { useAuthStore }					from '@/stores/authStore'
import messages               from '@/utils/messages';
import { CONST_CAMPAIGN_STATUS }   from '@/static/constants';

const campaignStore		= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();
const authStore 			= useAuthStore();

const onSaveCampaignBtnClick = async () => {
	try {
		pagesUtilStore.showLoading = true;

		try{
      await campaignStore.saveFormatAtmImageSlotOnS3();
    }catch(error){
      throw new Error("Erro ao realizar upload da imagem.");
    }
	
		await campaignStore.saveFocusedCampaign();

		messages.showSuccessMsg("Campanha salva com sucesso.");

	} catch (errorSavingCampaign) {
		
		messages.showErrorMsg(errorSavingCampaign.message);
		// messages.showErrorMsg("Erro ao tentar salvar a campanha...");
	
	} finally {
		pagesUtilStore.showLoading = false;
	}
};
</script>

<style scoped>

</style>
