export const SIDE_MENU_ITEM = Object.freeze({
	HOME				: {
		id		: 0,
		label : "Home"
	},

	CAMPAIGNS		: {
		id		: 1,
		label : "Campanhas"
	},

	MY_PROFILE	: {
		id		: 2,
		label : "Meu Perfil"
	},
});

export const SIDE_MENU_ITEMS = [
	{
		...SIDE_MENU_ITEM.HOME,
		orderById	: 0,
		enabled		: true
	},

	{
		...SIDE_MENU_ITEM.CAMPAIGNS,
		orderById	: 1,
		enabled		: true
	},

	{
		...SIDE_MENU_ITEM.MY_PROFILE,
		orderById	: 2,
		enabled		: true
	}
]