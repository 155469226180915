<template>
  <div>
    <div class="row fit no-wrap items-center content-start q-pb-md">
      <div class="col-6 col-md-6">
        <div class="row">
          <span class="midia-app-caption-sm">Anunciante</span>
        </div>

        <div class="row">
          <span class="midia-app-content-lg">{{ CONST_CAMPAIGN_TO_VIEW.advertiser }}</span>
        </div>
      </div>

      <div class="col-6 col-md-6">
        <div class="row"  v-if="props.propCampaignToView.statusDescription">
          <span class="midia-app-label-lg q-pr-sm">Status:</span>
          <span class="midia-app-content-lg status-value">{{ props.propCampaignToView.statusDescription }}</span>
        </div>
      </div>
    </div>

    <div class="row fit no-wrap content-start q-pb-md">
      <div class="col-6 col-md-6">
        <div class="q-pb-md">
          <div class="row">
            <span class="midia-app-caption-sm">Campanha</span>
          </div>

          <div class="row" style=" min-height: 20px; ">
            <span class="midia-app-content-lg">{{ CONST_CAMPAIGN_TO_VIEW.name }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-4 col-md-4">
            <div class="row">
              <span class="midia-app-caption-sm">Data Inicial</span>
            </div>

            <div class="row">
              <span class="midia-app-content-lg">{{ CONST_CAMPAIGN_TO_VIEW.startDateDdMmYyyy }}</span>
            </div>
          </div>

          <div class="col-4 col-md-4">
            <div class="row">
              <span class="midia-app-caption-sm">Data Final</span>
            </div>

            <div class="row">
              <span class="midia-app-content-lg">{{ CONST_CAMPAIGN_TO_VIEW.endDateDdMmYyyy }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-md-6">
        <div>
          <div class="row q-pb-xs">
            <span class="midia-app-label-sm">Instituição</span>
          </div>

          <div class="row fit no-wrap items-center q-pb-xs" style=" margin-left: -4px; ">
            <c-q-btn
              id="btn-icon-download-institutions"
              padding="none"
              propBtnLabel=""
              :propBtnFunction="onDownloadInstitutionsGreenBtnClick"
              :propBtnTransparent="true"
              :propBtnSize="EnumButtonSizes.SMALL"
              :propBorderless="true"
            >
              <template v-slot:default>
                <q-icon size="32px">
                  <img src="@/assets/icons/icon_HiOutlineDocumentDownload.svg" alt="green_arrow_down_icon">
                </q-icon>
              </template>
            </c-q-btn>

            <span class="midia-app-label-lg">{{ DEFAULT_IFS_EXPORT_FILENAME }}</span>
          </div>

          <div class="row q-py-xs">
            <c-q-btn
              id="btn-download-institutions"
              propBtnLabel="Download"
              :propBtnFunction="onDownloadInstitutionsBtnClick"
              :propBtnTransparent="true"
              :propBtnSize="EnumButtonSizes.SMALL"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EnumButtonSizes }              from '@/enums/enums';
import { useFinancialInstitutionStore } from '@/stores/financialInstitutionStore';
import { CampaignModel }                from '@/models/CampaignModel';
import { DEFAULT_IFS_EXPORT_FILENAME }	from '@/static/constants';

const financialInstitutionStore = useFinancialInstitutionStore();

const props = defineProps({
  propCampaignToView: {
    type      : CampaignModel,
    required  : true
  }
});

const CONST_CAMPAIGN_TO_VIEW = Object.freeze(new CampaignModel(props.propCampaignToView));

const onDownloadInstitutionsBtnClick = () => {
  if (CONST_CAMPAIGN_TO_VIEW.ifs) {
    financialInstitutionStore.exportIfsAsCsv(CONST_CAMPAIGN_TO_VIEW.ifs);
  }
}
const onDownloadInstitutionsGreenBtnClick = () => {
	onDownloadInstitutionsBtnClick();
}
</script>

<style scoped>
.status-value {
  color: var(--globalAppSecondaryColor5) !important;
}
</style>
