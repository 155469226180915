<template>
  <div>
    <div class="row fit no-wrap justify-center items-end content-start q-pb-md">
      <div class="col-grow q-pr-lg">
        <span class="midia-app-caption-sm">Nome da Campanha *</span>

        <q-input
          v-model="campaignStore.focusedCampaign.name"
          type="text"
          maxlength="500"
          color="globalAppPrimaryColor"
          bg-color="globalAppDefaultBackgroundColor"
          outlined
          dense
          placeholder="Informe o nome da Campanha"
          input-class="midia-app-content-lg"
          :readonly="campaignStore.focusedCampaign.iscampaignpublished"
        />
      </div>

      <div style=" width: 160px; ">
        <span class="midia-app-caption-sm">Período de Vigência *</span>
        <div>
          <q-input
            style=" outline: 1px solid var(--globalAppSecondaryColor2); "
            v-model="campaignStore.focusedCampaign.startDateDdMmYyyy"
            mask="##/##/####"
            bg-color="globalAppDefaultBackgroundColor"
            borderless
            dense
            input-class="midia-app-content-lg"
            @update:model-value="updateStartDate"
            
          >
            <template v-slot:prepend>
              <q-icon class="cursor-pointer q-pa-sm q-mr-sm" style=" background: var(--globalAppPrimaryColor); ">
                <img class="svg-icon" src="@/assets/icons/icon_HiOutlineCalendar.svg" />
              
                <q-popup-proxy ref="qPopupProxyStartDate" cover transition-show="scale" transition-hide="scale">
                  <q-date
                    v-model="campaignStore.focusedCampaign.startDate"
                    mask="YYYY-MM-DD"
                    minimal
                    :options="startDateOptions"
                    :default-year-month="`${startDateDefaultYearMonth}`"
                    color="globalAppPrimaryColor"
                    @update:model-value="updateStartDateDdMmYyyy"
                  >
                    <div class="row items-center justify-end">
                      <ButtonCloseModal />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
      </div>

      <div class="q-px-sm q-pb-sm">
        <span class="midia-app-caption-sm">até</span>
      </div>

      <div style=" width: 160px; ">
        <div>
          <q-input
            style=" outline: 1px solid var(--globalAppSecondaryColor2); "
            v-model="campaignStore.focusedCampaign.endDateDdMmYyyy"
            mask="##/##/####"
            bg-color="globalAppDefaultBackgroundColor"
            borderless
            dense
            input-class="midia-app-content-lg"
            @update:model-value="updateEndDate"
          >
            <template v-slot:prepend>
              <q-icon class="cursor-pointer q-pa-sm q-mr-sm" style=" background: var(--globalAppPrimaryColor); ">
                <img class="svg-icon" src="@/assets/icons/icon_HiOutlineCalendar.svg" />
              
                <q-popup-proxy ref="qPopupProxyEndDate" cover transition-show="scale" transition-hide="scale">
                  <q-date
                    v-model="campaignStore.focusedCampaign.endDate"
                    mask="YYYY-MM-DD"
                    minimal
                    :options="endDateOptions"
                    :default-year-month="`${endDateDefaultYearMonth}`"
                    color="globalAppPrimaryColor"
                    @update:model-value="updateEndDateDdMmYyyy"
                  >
                    <div class="row items-center justify-end">
                      <ButtonCloseModal />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
      </div>
    </div>

    <div class="row q-pb-md no-wrap">
      <div class="col-6 col-md-6" style=" padding-right: 14px;">
        <CampaignAdvertiser :prop-use-input="true" />       
      </div>

      <div class="col-6 col-md-6" style=" padding-left: 14px;">
        <CampaignAgency />
      </div>
    </div>

    <div class="row no-wrap">
      <div class="col-6 col-md-6" style=" padding-right: 14px;">
        <span class="midia-app-caption-sm">Valor do PI</span>

        <!-- <q-input
          v-model="campaignStore.focusedCampaign.piValue"
          mask="R$ #.##"
          fillmask="0"
          unmasked-value
          reverse-fill-mask
          color="globalAppPrimaryColor"
          bg-color="globalAppDefaultBackgroundColor"
          outlined
          dense
          placeholder="Informe o valor do PI"
          input-class="midia-app-content-lg text-right"
          :prefix="(campaignStore.focusedCampaign.piValue ? 'R$ ' : '')"
        /> -->
        <q-input
          v-model="campaignStore.focusedCampaign.piValue"
          mask="#.###.###.###.###,##"
          unmasked-value
          reverse-fill-mask
          color="globalAppPrimaryColor"
          bg-color="globalAppDefaultBackgroundColor"
          maxlength="15"
          outlined
          dense
          placeholder="Informe o valor do PI"
          input-class="midia-app-content-lg"
          prefix="R$ "
        />
      </div>

      <div class="col-6 col-md-6" style=" padding-left: 14px;">
        <CampaignFormat />
      </div>
    </div>

    <c-q-separator v-if="campaignStore.focusedCampaign.format" class="q-pt-lg q-pb-lg" />
  </div>
</template>

<script setup lang="ts">
import ButtonCloseModal                 from '@/components/ButtonCloseModal.vue';
import CampaignAdvertiser               from './CampaignAdvertiser.vue';
import CampaignAgency                   from './CampaignAgency.vue';
import CampaignFormat                   from './CampaignFormat.vue';
import { useCampaignStore }             from '@/stores/campaignStore';
import { ref, computed }                from 'vue';
import { convertDateToStringYyyyMmDd }  from '@/utils/commonUtils';
import { QPopupProxy }                  from 'quasar';

const campaignStore = useCampaignStore();

const qPopupProxyStartDate  : { value: QPopupProxy | null } = ref(null);
const qPopupProxyEndDate    : { value: QPopupProxy | null } = ref(null);

const updateStartDate = () => {
  if (campaignStore.focusedCampaign.startDateDdMmYyyy) {
    campaignStore.focusedCampaign.startDate = campaignStore.focusedCampaign.startDateDdMmYyyy.split('/').reverse().join('-');
  }
};

const updateStartDateDdMmYyyy = () => {
  if (campaignStore.focusedCampaign.startDate) {
    campaignStore.focusedCampaign.startDateDdMmYyyy = campaignStore.focusedCampaign.startDate.split('-').reverse().join('/');
  }

  if (qPopupProxyStartDate.value) {
    qPopupProxyStartDate.value.hide();
  }
};

const updateEndDate = () => {
  if (campaignStore.focusedCampaign.endDateDdMmYyyy) {
    campaignStore.focusedCampaign.endDate = campaignStore.focusedCampaign.endDateDdMmYyyy.split('/').reverse().join('-');
  }
};

const updateEndDateDdMmYyyy = () => {
  if (campaignStore.focusedCampaign.endDate) {
    campaignStore.focusedCampaign.endDateDdMmYyyy = campaignStore.focusedCampaign.endDate.split('-').reverse().join('/');
  }

  if (qPopupProxyEndDate.value) {
    qPopupProxyEndDate.value.hide();
  }
};

const startDateMinDateAllowedStringYyyyMmDd = computed(() => {
  const today = new Date();
  
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  
  return convertDateToStringYyyyMmDd(tomorrow);
});

const endDateMinDateAllowedStringYyyyMmDd = computed(() => {
  const oneDayAfterStartDate = (
    campaignStore.focusedCampaign.startDate
    ? new Date(campaignStore.focusedCampaign.startDate + " 00:00:00")
    : new Date(startDateMinDateAllowedStringYyyyMmDd.value + " 00:00:00")
  );
  
  oneDayAfterStartDate.setDate(oneDayAfterStartDate.getDate() + 1);

  return convertDateToStringYyyyMmDd(oneDayAfterStartDate);
});

const startDateDefaultYearMonth = computed(() => {
  const dateStringYyyyMm = startDateMinDateAllowedStringYyyyMmDd.value.replaceAll('-', '/').substring(0, 7);

  return dateStringYyyyMm;
});

const endDateDefaultYearMonth = computed(() => {
  const dateStringYyyyMm = endDateMinDateAllowedStringYyyyMmDd.value.replaceAll('-', '/').substring(0, 7);

  return dateStringYyyyMm;
});

const startDateOptions = (dateStringYyyyMmDd: string) => {
  return (dateStringYyyyMmDd >= startDateMinDateAllowedStringYyyyMmDd.value.replaceAll('-', '/'));
};

const endDateOptions = (dateStringYyyyMmDd: string) => {
  return (dateStringYyyyMmDd >= endDateMinDateAllowedStringYyyyMmDd.value.replaceAll('-', '/'));
};
</script>

<style scoped>

</style>
