<template>
  <div>
    <q-dialog
      v-model="pagesUtilStore.showModalDeleteCampaignConfirmation"
      :no-backdrop-dismiss="true"
    >
      <q-card class="midia-app-padding-modal" style=" width: 448px; ">
        <div>
					<c-modal-title
						:propModalTitle="(
              (pagesUtilStore.modalDeleteCampaignConfirmationMode == EnumMultipleSingleMode.SINGLE)   ? 'Excluir Campanha'    :
              (pagesUtilStore.modalDeleteCampaignConfirmationMode == EnumMultipleSingleMode.MULTIPLE) ? 'Excluir Campanha(s)' :
              ''
            )"
						:propModalDescription="(
              (pagesUtilStore.modalDeleteCampaignConfirmationMode == EnumMultipleSingleMode.SINGLE)   ? 'Deseja realmente excluir esta Campanha?'                   :
              (pagesUtilStore.modalDeleteCampaignConfirmationMode == EnumMultipleSingleMode.MULTIPLE) ? 'Deseja realmente excluir a(s) Campanha(s) selecionada(s)?' :
              ''
            )"
					/>

          <div class="row fit no-wrap justify-center q-pb-sm" style=" overflow: auto; ">
            <c-q-btn
              id="btn-excluir"
              style=" width: 100%; "
              propBtnLabel="EXCLUIR"
              :propBtnFunction="onDeleteCampaignConfirmationBtnClick"
              :propBtnTransparent="false"
              :propBtnSize="EnumButtonSizes.LARGE_1"
            />
          </div>
            
          <div class="row fit no-wrap justify-center" style=" overflow: auto; ">
            <c-q-btn
              id="btn-cancelar"
              style=" width: 100%; "
              propBtnLabel="CANCELAR"
              :propBtnTransparent="true"
              :propBtnSize="EnumButtonSizes.LARGE_1"
              v-close-popup
            />
          </div>
        </div>

        <!-- <q-inner-loading :showing="pagesUtilStore.showLoading">
          <q-spinner size="12em" />
        </q-inner-loading> -->
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup lang="ts">
import { useCampaignStore }                         from '@/stores/campaignStore';
import { usePagesUtilStore }                        from '@/stores/pagesUtilStore';
import { EnumMultipleSingleMode, EnumButtonSizes }  from '@/enums/enums';
import messages                                     from '@/utils/messages';

const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();

const onDeleteCampaignConfirmationBtnClick = async () => {
  try {
    pagesUtilStore.showLoading = true;

    switch (pagesUtilStore.modalDeleteCampaignConfirmationMode) {
      case EnumMultipleSingleMode.SINGLE:
        try {
          await campaignStore.deleteFocusedCampaign();

          messages.showSuccessMsg("Campanha excluída com sucesso!");
          
          await campaignStore.fetchCampaigns();
          
        } catch (errorDeletingCampaign) {
          messages.showErrorMsg("Erro ao tentar excluir a campanha...");
        }
        
        break;

      case EnumMultipleSingleMode.MULTIPLE:
        if (campaignStore.allSelectedCampaignsCanBeDeleted()) {
          try {
            await campaignStore.deleteSelectedCampaigns();

            messages.showSuccessMsg("Campanha(s) excluída(s) com sucesso! Atualizando lista de campanhas...");

            await campaignStore.fetchCampaigns();
            
          } catch (errorDeletingCampaigns) {
            messages.showErrorMsg("Erro ao tentar excluir a(s) campanha(s) selecionada(s)...");
          }
        }

        break;
        
      default:
        break;
    }

  } finally {
    pagesUtilStore.showLoading = false;

    pagesUtilStore.showModalDeleteCampaignConfirmation  = false;
    pagesUtilStore.showModalPageCampaignsTableActions   = false;
  }      
};
</script>

<style scoped>

</style>