import { pcInterface, pcFilterInterface }	from '@/types/types.d';
import { PcModel }						    from '@/models/PcModel';

export class PcFilterModel implements pcFilterInterface {
	filterPc							: pcInterface;

	constructor () {
		this.filterPc	= new PcModel({});
	}
}
