import script from "./CampaignInstitutions.vue?vue&type=script&setup=true&lang=ts"
export * from "./CampaignInstitutions.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QSelect,QChip});
