<template>
	<div id="div-header-layout" class="q-mx-xl q-mt-lg q-pb-md">
		<div class="flex align-top justify-between q-pb-xs">
			<span class="midia-app-title-header-xl">{{ computedCurrentPageTitle }}</span>

			<div>
				<div class="flex items-baseline">
					<span class="midia-app-label-user-logged-in-email-lg q-pr-sm">{{ computedCurrentLoggedInUserEmail }}</span>

					<q-icon size="14px" class="cursor-pointer" style=" background-color: var(--globalAppPrimaryColor); padding: 5px; border-radius: 50%; ">
						<img src="@/assets/icons/icon_perfil_white.svg" />
					</q-icon>
					<q-menu @before-show="authStore.showMenu = true" @before-hide="authStore.showMenu = false">
            <q-list>
                <q-item
                  class="user-options exit"
                  active-class="user-options"
                  clickable 
                  v-close-popup
									@click="onLogoutBtnClick"
                >
                  <q-item-section avatar>
                      <q-avatar><img class="svg-icon" src="@/assets/icons/icon_sair.svg"></q-avatar>
                  </q-item-section>
                  <q-item-section>
                      <q-item-label>Sair</q-item-label>
                  </q-item-section>
                </q-item>
            </q-list>
          </q-menu>
				</div>
			</div>
		</div>

		<div style=" width: 70%; min-height: 20px; ">
			<span class="midia-app-label-lg q-pr-sm">{{ computedCurrentPageDescription }}</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import { EnumRouteNames }  		from '@/enums/enums';
import { usePagesUtilStore }  from '@/stores/pagesUtilStore';
import { useAuthStore }       from '@/stores/authStore';
import { computed }						from 'vue';
import { useRouter }					from 'vue-router';

const pagesUtilStore  = usePagesUtilStore();
const authStore       = useAuthStore();
const router 					= useRouter();

const computedCurrentPageTitle = computed(() => {
	return pagesUtilStore.currentPageTitle;
})

const computedCurrentPageDescription = computed(() => {
	return pagesUtilStore.currentPageDescription;
})

const computedCurrentLoggedInUserEmail = computed(() => {
	return authStore.currentLoggedInUserEmail;
})

const onLogoutBtnClick = () => {
  authStore.clear();
  router.push({ name: EnumRouteNames.ROOT });
}
</script>

<style scoped>

</style>
