import Notify from 'quasar/src/plugins/notify/Notify.js';;

const messages = {
	showSuccessMsg(messageToShow: string) {
		Notify.create({
			icon 			: "check",
			position	: "top-right",
			color			: "globalSuccessMsgColor",
			textColor	: "globalAppDefaultBackgroundColor",
			message		: messageToShow
		});
	},

	showWarningMsg(messageToShow: string) {
		Notify.create({
			icon 			: "warning",
			position	: "top-right",
			color			: "globalWarningMsgColor",
			textColor	: "globalAppDefaultBackgroundColor",
			message		: messageToShow
		});
	},

	showErrorMsg(messageToShow: string) {
		Notify.create({
			icon 			: "error",
			position	: "top-right",
			color			: "globalErrorMsgColor",
			textColor	: "globalAppDefaultBackgroundColor",
			message		: messageToShow
		});
	},
}

export default messages;
