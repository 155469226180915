<template>
  <div>
    <div class="row q-pb-xs">
      <span class="midia-app-label-sm">Formato: {{ campaignStore.focusedCampaign.format }}</span>
    </div>

    <div class="row fit no-wrap items-center content-start q-pb-md">
      <CampaignFormatSlotIcon v-if="campaignStore.focusedCampaign.slot1"
        :propSlotType="EnumATMScreenFormatSlotTypes.A1"
        propSlotLabel="Abertura"
        :propShowSlotTypeDescriptionBelowIcon="true"
      />

      <CampaignFormatSlotIcon v-if="campaignStore.focusedCampaign.slot2"
        :propSlotType="EnumATMScreenFormatSlotTypes.A2"
        propSlotLabel="PropagIF"
        :propShowSlotTypeDescriptionBelowIcon="true"
      />

      <CampaignFormatSlotIcon v-if="campaignStore.focusedCampaign.slot3"
        :propSlotType="EnumATMScreenFormatSlotTypes.A3"
        propSlotLabel="Retire o seu Dinheiro"
        :propShowSlotTypeDescriptionBelowIcon="true"
      />

      <CampaignImageInformationDisplayCard />
    </div>
  </div>
</template>

<script setup lang="ts">
import CampaignFormatSlotIcon               from '@/components/campaign/CampaignFormatSlotIcon.vue';
import CampaignImageInformationDisplayCard  from '@/components/campaign/CampaignImageInformationDisplayCard.vue';
import { useCampaignStore }                 from '@/stores/campaignStore';
import { EnumATMScreenFormatSlotTypes }     from '@/enums/enums';

const campaignStore = useCampaignStore();

</script>

<style scoped>

</style>
