<template>
  <div id="slot-icon" class="q-pr-md">
    <q-icon size="100px">
      <img src="@/assets/icons/icon_slot_Group_9251.svg" alt="slot_image">
    </q-icon>

    <span id="slot-type-name-inside-icon">{{ props.propSlotType }}</span>
    <span id="slot-type-description-below-icon" class="midia-app-label-format-atm-slot-lg" v-if="props.propShowSlotTypeDescriptionBelowIcon">{{ props.propSlotLabel }}</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  propSlotType: {
    type      : String,
    required  : true
  },

  propSlotLabel: {
    type      : String,
    required  : false
  },

  propShowSlotTypeDescriptionBelowIcon: {
    type      : Boolean,
    required  : false
  }
});
</script>

<style scoped>
#slot-icon {
  position: relative;
  overflow: hidden;
  width: 190px;
}

#slot-type-name-inside-icon {
  font-size: 30px !important;
  line-height: 47px  !important;
  color: var(--globalAppDefaultBackgroundColor) !important;
  font-weight: 900  !important;
  
  position: absolute;
  left: 52px;
  top: 23px;
}

#slot-type-description-below-icon {
  position: absolute;
  left: 0;
  top: 78px; 
  width: 200px;
}
</style>
