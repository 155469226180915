<template>
  <div class="flex items-center justify-center" style=" min-height: inherit; background-color: var(--globalAppSecondaryColor4); ">
    <h2>Conteúdo da página MEU PERFIL</h2>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>
