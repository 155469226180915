<template>
	<div>
		<q-dialog
			v-model="pagesUtilStore.showModalImagePreview"
			:no-backdrop-dismiss="true"
		>
			<q-card class="midia-app-padding-modal" style=" width: 820px; max-width: 80vw; ">
				<div>
					<c-modal-title propModalTitle="Pré-visualização" />

					<div class="row flex no-wrap justify-center items-center content-center q-pb-lg" style=" overflow: auto; ">
						<!-- <img src="@/assets/images/imagem_exemplo_campanha_mcdia_feliz.png" alt="img_campanha" style=" width: 750px; height: 425px; object-fit: contain; " /> -->
						<!-- <img src="@/assets/images/imagem_exemplo_campanha_mcdia_feliz.png" alt="img_campanha" style=" height: 425px; object-fit: contain; " /> -->
						<img :src="props.propImgObjectUrl" alt="img_campanha" style=" height: 425px; object-fit: contain; " />
						<!-- <img src="blob:http://localhost:8080/3e99c328-4faa-4386-9d76-ee89921c059e" alt="img_campanha" style=" height: 425px; object-fit: contain; " /> -->
					</div>
				</div>

				<div class="row fit no-wrap justify-end" style=" overflow: auto; ">
					<div v-if="propShowDownloadButton" class="q-pr-sm">
						<c-q-btn
							id="btn-download-image"
							propBtnLabel="Download"
							:propBtnFunction="onDownloadImageBtnClick"
							:propBtnTransparent="true"
							:propBtnSize="EnumButtonSizes.LARGE_1"
						/>
					</div>

					<div class="q-pl-sm">
						<c-q-btn
							id="btn-close-modal"
							propBtnLabel="Fechar"
							:propBtnTransparent="true"
							:propBtnSize="EnumButtonSizes.LARGE_1"
							v-close-popup
						/>
					</div>
				</div>
			</q-card>
		</q-dialog>
	</div>
</template>

<script setup lang="ts">
import { EnumButtonSizes }		from '@/enums/enums';
import { usePagesUtilStore }	from '@/stores/pagesUtilStore';
import { onBeforeMount }			from 'vue';

const pagesUtilStore = usePagesUtilStore();

const props = defineProps({
	propImgObjectUrl: {
		type			: String,
		required	: true
	},

	propShowDownloadButton: {
		type			: Boolean,
		required	: false,
		default		: true
	}
});

const onDownloadImageBtnClick = async () => {
	console.log("onDownloadImageBtnClick!");
};

onBeforeMount(() => {
	console.log("props.propImgObjectUrl:", props.propImgObjectUrl);
});

</script>

<style scoped>

</style>
