<template>
  <div>
    <q-dialog
      v-model="pagesUtilStore.showModalFilterPCs"
      :no-backdrop-dismiss="true"
    >
      <q-card class="midia-app-padding-modal" style=" width: 500px; max-width: 80vw; ">
        <div>
                    <c-modal-title
                        propModalTitle="Filtro de PCs"
                        propModalDescription="Pesquise um PC"
                    />      

          <div class="q-pb-md">
                        <div class="row q-pb-xs">
                                <span class="midia-app-label-modal-sm">Código do PC</span>
                        </div>

                        <div class="row col">
                            <q-input
                                v-model="pcStore.pcFilter.filterPc.codpontocaptura"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                                outlined
                                dense
                                placeholder="Informe o Código do PC"
                                input-class="midia-app-content-lg"
                                style=" width: 100%; "
                            />
                        </div>
                    </div>

          <div class="q-pb-md">
                        <div class="row q-pb-xs">
                                <span class="midia-app-label-modal-sm">Nome do PC</span>
                        </div>

                        <div class="row col">
                            <q-input
                                v-model="pcStore.pcFilter.filterPc.nompontocaptura"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                                outlined
                                dense
                                placeholder="Informe o Nome do PC"
                                input-class="midia-app-content-lg"
                                style=" width: 100%; "
                            />
                        </div>
                    </div> 

          

          <div class="q-pb-md">
                        <div class="row q-pb-xs">
                                <span class="midia-app-label-modal-sm">Cidade</span>
                        </div>

                        <div class="row col">
                            <q-input
                                v-model="pcStore.pcFilter.filterPc.nomcidredeautoatndto"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                                outlined
                                dense
                                placeholder="Informe a Cidade"
                                input-class="midia-app-content-lg"
                                style=" width: 100%; "
                            />
                        </div>
                    </div>

          <div class="q-pb-md">
                        <div class="row q-pb-xs">
                                <span class="midia-app-label-modal-sm">UF</span>
                        </div>

                        <div class="row col">
                            <q-input
                                v-model="pcStore.pcFilter.filterPc.ufredeautoatndto"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                                outlined
                                dense
                                placeholder="Informe a UF"
                                input-class="midia-app-content-lg"
                                style=" width: 100%; "
                            />
                        </div>
                    </div>

          <div class="q-pb-md">
                        <div class="row q-pb-xs">
                                <span class="midia-app-label-modal-sm">Subrede</span>
                        </div>

                        <div class="row col">
                            <q-input
                                v-model="pcStore.pcFilter.filterPc.nomsubredeautoatndto"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                                outlined
                                dense
                                placeholder="Informe a Subrede"
                                input-class="midia-app-content-lg"
                                style=" width: 100%; "
                            />
                        </div>
                    </div>

          <div class="q-pb-md">
                        <div class="row q-pb-xs">
                                <span class="midia-app-label-modal-sm">Segmento</span>
                        </div>

                        <div class="row col">
                            <q-input
                                v-model="pcStore.pcFilter.filterPc.nomsegmmerclocalptoatndto"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                                outlined
                                dense
                                placeholder="Informe o Segmento"
                                input-class="midia-app-content-lg"
                                style=" width: 100%; "
                            />
                        </div>
                    </div>

          <div class="q-pb-md">
                        <div class="row q-pb-xs">
                                <span class="midia-app-label-modal-sm">Tipo de Máquina</span>
                        </div>

                        <div class="row col">
                            <q-input
                                v-model="pcStore.pcFilter.filterPc.nomtpmaqautoatndto"
                color="globalAppPrimaryColor"
                bg-color="globalAppDefaultBackgroundColor"
                                outlined
                                dense
                                placeholder="Informe o Tipo de Máquina"
                                input-class="midia-app-content-lg"
                                style=" width: 100%; "
                            />
                        </div>
                    </div> 

          <div class="row fit no-wrap justify-center items-center content-start q-pb-lg">
            
          


          <div class="row fit no-wrap justify-end" style=" overflow: auto; ">
            <div class="q-pl-md" style=" overflow: auto; ">
              <c-q-btn
                id="btn-fetch"
                propBtnLabel="Buscar"
                :propBtnFunction="onFetchBtnClick"
                :propBtnTransparent="false"
                :propBtnSize="EnumButtonSizes.LARGE_1"
              />
            </div>
            <div class="q-pl-md" style=" overflow: auto; ">
            <c-q-btn
              id="btn-continue"
              propBtnLabel="Continuar"
              :propBtnFunction="onContinueBtnClick"
              :propBtnTransparent="false"
              :propBtnSize="EnumButtonSizes.LARGE_1"
            />
          </div>
          </div>

        </div>

          <ModalPCs v-if="pagesUtilStore.showModalPCs" />

         <!-- <q-inner-loading
                        :showing="pagesUtilStore.showLoading"
                        label="aguarde..." 
                        /> -->
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup lang="ts">
import { EnumButtonSizes }	  from '@/enums/enums';
import { usePCStore }         from '@/stores/pcStore';
import { useCampaignStore }   from '@/stores/campaignStore';
import { usePagesUtilStore }  from '@/stores/pagesUtilStore';
import { computed }           from 'vue';
import { PcModel }            from '@/models/PcModel';
import messages               from '@/utils/messages';
import ModalPCs					      from './ModalPCs.vue';

const pcStore         = usePCStore();
const campaignStore   = useCampaignStore();
const pagesUtilStore  = usePagesUtilStore();

//NOVO
const onFetchBtnClick = () => { 
  pcStore.resetState();
  try {
    pagesUtilStore.showLoading = true;

    if(!pcStore.pcFilter.filterPc.codpontocaptura
    && !pcStore.pcFilter.filterPc.nompontocaptura
    && !pcStore.pcFilter.filterPc.nomcidredeautoatndto
    && !pcStore.pcFilter.filterPc.ufredeautoatndto
    && !pcStore.pcFilter.filterPc.nomsubredeautoatndto 
    && !pcStore.pcFilter.filterPc.nomsegmmerclocalptoatndto
    && !pcStore.pcFilter.filterPc.nomtpmaqautoatndto
  ) {
    messages.showWarningMsg("Pelo menos um filtro deve ser preenchido.");
  }

  else {
    pagesUtilStore.showModalPCs = true;
  }     
  
  } finally {
    pagesUtilStore.showLoading = false;
  }
}

const onContinueBtnClick = () => {
    pagesUtilStore.showModalFilterPCs = false; 
};

const selectedPcsNotYetOnTheCampaign = computed(() => {
  return pcStore.selectedPCs.filter(
    pc => !campaignStore.focusedCampaign.pcs.some(
      campaignPc => (campaignPc.codpontocaptura == pc.codpontocaptura)
    )
  );
});

</script>

<style scoped>

</style>
