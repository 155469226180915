<template>
	<div>
		<div class="row items-center q-pb-xs">
			<span id="span-01" class="midia-app-label-sm q-pr-sm">Instituições *</span>

			<c-q-btn
				id="btn-clear"
				propBtnLabel="Limpar"
				:propBtnFunction="onClearIfsBtnClick"
				:propBtnTransparent="true"
				:propBtnSize="EnumButtonSizes.SMALL"
				:disable="disableClearBtn"
			/>
		</div>

		<div id="div-01" class="row no-wrap items-center">
			<!-- <div id="div-02" class="q-pr-md col" style=" height: 67px; overflow: hidden; "> -->
			<div id="div-02" style="max-height: 100px; overflow-y: scroll; width:100%;">
				<q-select
					v-model="campaignStore.focusedCampaign.ifs"
					color="globalAppPrimaryColor"
					bg-color="globalAppDefaultBackgroundColor"
					outlined
					dense
					use-chips
					multiple
					hide-dropdown-icon
					input-debounce="250"
					new-value-mode="add-unique"
					input-style=" white-space: nowrap; overflow: hidden; text-overflow: ellipsis; "
				>
					<template v-slot:selected-item="scope">
						<q-chip
							removable
							@remove="onRemove(scope)"
							:tabindex="scope.tabindex"
							color="globalAppPrimaryColor"
							text-color="globalAppDefaultBackgroundColor"
							class="q-ma-xs"
							size="sm"
						>
							{{ scope.opt.codif }} - {{ scope.opt.nome }}
						</q-chip>
					</template>
				</q-select>
			</div>

			<div id="div-03" class="col-auto">
				<c-q-btn
					id="btn-filtrar"
					propBtnLabel="Filtrar"
					:propBtnFunction="onFilterBtnClick"
					:propBtnTransparent="true"
					:propBtnSize="EnumButtonSizes.LARGE_1"
				/>
			</div>
		</div>

		<ModalFinancialInstitutions />
		
		<ModalConfirmationDialog
			propDialogTitle="Atenção!"
			propConfirmationMsg="Tem certeza de que deseja excluir todas as instituições selecionadas?"
			propDialogType="institutions"
			@confirm="onClearSelectedInstitutionsConfirmation"
		/>
	</div>
</template>

<script setup lang="ts">
import ModalFinancialInstitutions	from '@/views/modals/ModalFinancialInstitutions.vue';
import ModalConfirmationDialog		from '@/views/modals/ModalConfirmationDialog.vue';
import { EnumButtonSizes }				from '@/enums/enums';
import { useCampaignStore }				from '@/stores/campaignStore';
import { usePagesUtilStore }      from '@/stores/pagesUtilStore';
import { computed }								from 'vue';

const campaignStore		= useCampaignStore();
const pagesUtilStore	= usePagesUtilStore();

const removeIfIdFromIfsIds = (ifIdToRemove: string) => {
	const index = campaignStore.focusedCampaign.ifsIds.indexOf(ifIdToRemove, 0);

	if (index > -1) {
		campaignStore.focusedCampaign.ifsIds.splice(index, 1);
	}
};

const onRemove = (
	scope: {
    index: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opt: any;
    selected: boolean;
    removeAtIndex: (index: number) => void;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleOption: (opt: any) => void;
    tabindex: number;
	}
) => {
	scope.removeAtIndex(scope.index);

	removeIfIdFromIfsIds(scope.opt.codif);
};

const disableClearBtn = computed(() => {
	try {
		return (campaignStore.focusedCampaign.ifs.length == 0);
		
	} catch (error) {
		return false;
	}
});

/*const onClearBtnClick = () => {
  pagesUtilStore.showModalConfirmationDialog = true;
};*/

//NOVO
const onClearIfsBtnClick = () => {
  pagesUtilStore.showModalConfirmationDialogIfs = true;
};

const onClearSelectedInstitutionsConfirmation = () => {
	campaignStore.focusedCampaign.ifs.length = 0;
};

const onFilterBtnClick = () => {
  pagesUtilStore.showModalFinancialInstitutions = true;
};
</script>

<style scoped>

</style>