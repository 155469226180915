<template>
  <div>
    <div class="row fit no-wrap justify-center items-center content-start">
      <CampaignFormatSlotIcon
        :propSlotType="props.propSlotType"
        :propSlotLabel="props.propSlotLabel"
      />

      <div class="col-grow" style=" overflow: auto; ">
        <div class="row">
          <span class="midia-app-label-format-atm-slot-lg">{{ props.propSlotLabel }}</span>
        </div>

        <div class="row items-center no-wrap">
          <div class="col-grow q-py-sm">
            <q-input
              v-if="(props.propSlotType == EnumATMScreenFormatSlotTypes.A1)"
              id="q-input-slot-a1"
              v-model="qInputSlot01Model"
              color="globalAppPrimaryColor" 
              bg-color="globalAppDefaultBackgroundColor"
              outlined
              dense
              :placeholder="`Selecione uma imagem .png com resolução de ${ATM_ALLOWED_IMAGE_DIMENSIONS.width}x${ATM_ALLOWED_IMAGE_DIMENSIONS.height} pixels.`"
              input-class="midia-app-content-sm"
              readonly
            />

            <q-input
              v-else-if="(props.propSlotType == EnumATMScreenFormatSlotTypes.A2)"
              id="q-input-slot-a2"
              v-model="qInputSlot02Model"
              color="globalAppPrimaryColor" 
              bg-color="globalAppDefaultBackgroundColor"
              outlined
              dense
              :placeholder="`Selecione uma imagem .png com resolução de ${ATM_ALLOWED_IMAGE_DIMENSIONS.width}x${ATM_ALLOWED_IMAGE_DIMENSIONS.height} pixels.`"
              input-class="midia-app-content-sm"
              readonly
            />

            <q-input
              v-else-if="(props.propSlotType == EnumATMScreenFormatSlotTypes.A3)"
              id="q-input-slot-a3"
              v-model="qInputSlot03Model"
              color="globalAppPrimaryColor" 
              bg-color="globalAppDefaultBackgroundColor"
              outlined
              dense
              :placeholder="`Selecione uma imagem .png com resolução de ${ATM_ALLOWED_IMAGE_DIMENSIONS.width}x${ATM_ALLOWED_IMAGE_DIMENSIONS.height} pixels.`"
              input-class="midia-app-content-sm"
              readonly
            />
          </div>

          <div class="q-px-md q-py-sm" style=" overflow: auto; ">
            <q-file
              v-model="fileModel"
              ref="qFileUploader"
              accept="image/png"
              style=" display: none; "
              v-on:update:model-value="onFileSelected"
            />

            <c-q-btn
              id="btn-search-more"
              propBtnLabel="Procurar"
              :propBtnFunction="onSearchMoreBtnClick"
              :propBtnTransparent="true"
              :propBtnSize="EnumButtonSizes.MEDIUM"
            >
              <template v-slot:afterLabel>
                <q-icon class="q-pl-sm" size="16px">
                  <img src="@/assets/icons/icon_CkAdd.svg" />
                </q-icon>
              </template>
            </c-q-btn>
          </div>

          <div class="q-py-sm" style=" overflow: auto; ">
            <c-q-btn
              id="btn-import"
              propBtnLabel="Importar"
              :propBtnFunction="onImportBtnClick"
              :propBtnTransparent="false"
              :propBtnSize="EnumButtonSizes.MEDIUM"
              :disable="!campaignStore.campaignJsonSlot"
            >
              <template v-slot:afterLabel>
                <q-icon class="q-pl-sm" size="12px">
                  <img src="@/assets/icons/icon_CkArrowDown.svg" />
                </q-icon>
              </template>
            </c-q-btn>
          </div>
        </div>

        <div class="row fit no-wrap justify-start items-center content-start">
          <c-q-btn
            id="btn-icon-download-image"
            padding="none"
            propBtnLabel=""
            :propBtnFunction="onDownloadImageGreenBtnClick"
            :propBtnTransparent="true"
            :propBtnSize="EnumButtonSizes.SMALL"
            :propBorderless="true"
            :disable="!computedFocusedCampaignSlot"
          >
            <template v-slot:default>
              <q-icon size="32px">
                <img src="@/assets/icons/icon_HiOutlinePhotograph.svg" alt="green_img_icon">
              </q-icon>
            </template>
          </c-q-btn>

          <span>{{ computedFocusedCampaignSlot }}</span>
        </div>
      </div>
    </div>

    <!-- <q-btn
      label="testa upload imagem"
      @click="testaUploadImagem"
    /> -->

    <ModalImagePreview
      :propImgObjectUrl="campaignStore.focusedCampaignImageObjectUrl"
      :propShowDownloadButton="false"
    />
    <!-- <ModalImagePreview
      propImgObjectUrl="blob:http://localhost:8080/3e99c328-4faa-4386-9d76-ee89921c059e"
    /> -->
  </div>
</template>

<script setup lang="ts">
import CampaignFormatSlotIcon                             from '@/components/campaign/CampaignFormatSlotIcon.vue';
import { EnumATMScreenFormatSlotTypes, EnumButtonSizes }  from '@/enums/enums';
import { useCampaignStore }                               from '@/stores/campaignStore';
import { ref, Ref, computed }                             from 'vue';
import { QFile }                                          from 'quasar';
import ModalImagePreview			                            from '@/views/modals/ModalImagePreview.vue';
import { usePagesUtilStore }	                            from '@/stores/pagesUtilStore';
import { ATM_ALLOWED_IMAGE_DIMENSIONS }                   from '@/static/constants';
import messages                                           from '@/utils/messages';
// import { campaignJsonSlotInterface }                      from '@/types/types';

const props = defineProps({
  propSlotType  : {
    type      : String,
    required  : true
  },

  propSlotLabel : String
});

const campaignStore         = useCampaignStore();
const pagesUtilStore        = usePagesUtilStore();

const qFileUploader         = ref() as Ref<QFile>;
const fileModel             = ref<File | undefined>(undefined);
const selectedImage         = ref<HTMLImageElement | null>(null);

const qInputSlot01Model     = ref<string | null>(null);
const qInputSlot02Model     = ref<string | null>(null);
const qInputSlot03Model     = ref<string | null>(null);

// const campaignJsonSlot      = ref<campaignJsonSlotInterface | undefined>(undefined);

const showImagePreview = (imageObjectUrl: string | undefined) => {
  if (imageObjectUrl) {
    campaignStore.focusedCampaignImageObjectUrl = imageObjectUrl;
  
    pagesUtilStore.showModalImagePreview = true;
  }
};

const onDownloadImageGreenBtnClick = () => {
  console.log("onDownloadImageGreenBtnClick!");
};

const onFileSelected = (selectedFile: File) => {
  try {
    if (selectedFile) {

      if (selectedFile.name.length > 50) {
        messages.showWarningMsg(`O nome do arquivo deve ter no máximo 50 caracteres.`);
        return;
      }

      const reader = new FileReader();

      reader.onload = (event) => {
        // Create a new Image to get the dimensions
        selectedImage.value = new Image();

        selectedImage.value.onload = function() {
          if (
            selectedImage.value
            && (
              (selectedImage.value.width != ATM_ALLOWED_IMAGE_DIMENSIONS.width)
              || (selectedImage.value.height != ATM_ALLOWED_IMAGE_DIMENSIONS.height)
            )
          ) {
            messages.showWarningMsg(`A imagem precisa ter 640x480 pixels, mas possui ${selectedImage.value.width}x${selectedImage.value.height}.`);
          
          } else {
            campaignStore.campaignJsonSlot = {
              imageObjectUrl  : URL.createObjectURL(selectedFile),
              imageS3Url      : undefined,
              imageFileName   : selectedFile.name,
              imageWidth		  : selectedImage.value?.width,
              imageHeight		  : selectedImage.value?.height,
              imageSize			  : selectedFile.size
            }

            switch (props.propSlotType) {
              case EnumATMScreenFormatSlotTypes.A1:
                qInputSlot01Model.value = selectedFile.name;
                
                break;
            
              case EnumATMScreenFormatSlotTypes.A2:
                qInputSlot02Model.value = selectedFile.name;
                
                break;
            
              case EnumATMScreenFormatSlotTypes.A3:
                qInputSlot03Model.value = selectedFile.name;
                
                break;
            
              default:
                break;
            }
            showImagePreview(campaignStore.campaignJsonSlot.imageObjectUrl);
          };
        }

        if (event.target) {
          selectedImage.value.src = event.target.result as string;
        }
      }

      reader.readAsDataURL(selectedFile);
    }
    
  } catch (error) {
    console.error(error);
  }
};

const onSearchMoreBtnClick = () => {
  if (qFileUploader.value) {
    qFileUploader.value.pickFiles();
  }
};

const onImportBtnClick = () => {
  if (campaignStore.campaignJsonSlot) {
    if (selectedImage.value) {
      campaignStore.formatAtmScreenImageFileSlot = fileModel.value;
    }

    switch (props.propSlotType) {
      case EnumATMScreenFormatSlotTypes.A1:
        campaignStore.focusedCampaign.slot1 = campaignStore.convertCampaignJsonSlotInterfaceToCampaignStringSlot(campaignStore.campaignJsonSlot);
        campaignStore.focusedCampaign.slot2 = undefined;
        campaignStore.focusedCampaign.slot3 = undefined;
        break;
    
      case EnumATMScreenFormatSlotTypes.A2:
        campaignStore.focusedCampaign.slot1 = undefined;
        campaignStore.focusedCampaign.slot2 = campaignStore.convertCampaignJsonSlotInterfaceToCampaignStringSlot(campaignStore.campaignJsonSlot);
        campaignStore.focusedCampaign.slot3 = undefined;
        
        break;
    
      case EnumATMScreenFormatSlotTypes.A3:
        campaignStore.focusedCampaign.slot1 = undefined;
        campaignStore.focusedCampaign.slot2 = undefined;
        campaignStore.focusedCampaign.slot3 = campaignStore.convertCampaignJsonSlotInterfaceToCampaignStringSlot(campaignStore.campaignJsonSlot);
        
        break;
    
      default:
        break;
    }

    qInputSlot01Model.value = null;
    qInputSlot02Model.value = null;
    qInputSlot03Model.value = null;

    campaignStore.campaignJsonSlot  = undefined;
  }
};

const computedFocusedCampaignSlot = computed(() => {
  try {
    switch (props.propSlotType) {
      case EnumATMScreenFormatSlotTypes.A1:
        return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot1)?.imageFileName;
    
      case EnumATMScreenFormatSlotTypes.A2:
        return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot2)?.imageFileName;
        
      case EnumATMScreenFormatSlotTypes.A3:
        return campaignStore.convertCampaignStringSlotToCampaignJsonSlotInterface(campaignStore.focusedCampaign.slot3)?.imageFileName;
        
      default:
        return "";
    }
    
  } catch (error) {
    return "";
  }
});

/* const testaUploadImagem = async () => {
  await campaignStore.sendImageToS3(fileModel.value);
} */
</script>

<style scoped>

</style>
