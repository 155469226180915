<template>
  <c-q-btn
    id="back-button"
    propBtnLabel="Voltar"
    :propBtnFunction="backToPreviousPage"
    :propBtnTransparent="true"
    :propBtnSize="EnumButtonSizes.SMALL"
  >
    <template v-slot:default>
      <q-icon size="16px" class="q-mr-xs">
        <img class="svg-icon" src="@/assets/icons/icon_arrow_back.svg" style=" width: 18px; height: 24px; "/>
      </q-icon>
    </template>
  </c-q-btn>
</template>

<script setup lang="ts">
import { EnumButtonSizes, EnumRouteNames }	from '@/enums/enums';
import { useRouter, useRoute }        from 'vue-router';


const router = useRouter();
const route = useRoute();

const backToPreviousPage = () => {
  
  if(route.name == EnumRouteNames.CAMPAIGN_VIEW) {
    router.push({ name: EnumRouteNames.CAMPAIGN_HOME });
  } 
  else {
    router.back();
  }
  
			
};
</script>

<style scoped>

</style>