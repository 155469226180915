<template>
	<c-q-btn
		id="btn-delete-focused-campaign"
		propBtnLabel="Excluir"
		:propBtnFunction="onDeleteFocusedCampaignBtnClick"
		:propBtnTransparent="true"
		:propBtnSize="props.propBtnSize"
		:disable="disableDeleteFocusedCampaignButton"
	/>
</template>

<script setup lang="ts">
import { EnumMultipleSingleMode, EnumButtonSizes }	from '@/enums/enums';
import { usePagesUtilStore }												from '@/stores/pagesUtilStore';
import { useCampaignStore }													from '@/stores/campaignStore';
import { computed }																	from 'vue';

const pagesUtilStore	= usePagesUtilStore();
const campaignStore		= useCampaignStore();

const props = defineProps({
  propBtnSize: {
		type			: String as () => keyof typeof EnumButtonSizes,
		required	: true,
		default		: EnumButtonSizes.LARGE_1
	}
});

const onDeleteFocusedCampaignBtnClick = () => {
  pagesUtilStore.modalDeleteCampaignConfirmationMode = EnumMultipleSingleMode.SINGLE;
  pagesUtilStore.showModalDeleteCampaignConfirmation = true;
};

const disableDeleteFocusedCampaignButton = computed(() => {
	return !campaignStore.campaignCanBeDeleted(campaignStore.focusedCampaign);
});
</script>

<style scoped>

</style>
